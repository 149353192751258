"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConfig_1 = require("../BaseConfig");
const Environment_1 = require("../../models/Environment");
const ag08Config = {
    env: Environment_1.BuildEnvironment.Ag08,
    ariaCollectorUri: "https://collector.azure.eaglex.ic.gov/Collector/3.0/",
    OneDsCollectorUri: "https://collector.azure.eaglex.ic.gov/OneCollector/1.0/",
    publicPath: "https://statics.teams.eaglex.ic.gov",
    telemetry: {
        tenantToken: "b46346d18f6e4de2944762c998242aea-223b6782-e3f9-41ac-9fbc-1243bdfd8801-7804",
    },
    ecs: Object.assign(Object.assign({}, BaseConfig_1.default.ecs), { host: "https://config.ecs.teams.eaglex.ic.gov" }),
};
exports.default = ag08Config;
