"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const Environment_1 = require("../models/Environment");
const FeaturesService_1 = require("../services/FeaturesService");
const Utils_1 = require("../helpers/Utils");
const OneDsSerivce_1 = require("./OneDsSerivce");
const ServerParamsService_1 = require("./ServerParamsService");
const ConfigurationService_1 = require("./ConfigurationService");
const osRegExps = {};
osRegExps[Environment_1.OperatingSystems.WinPhone] = /windows\sphone/i;
osRegExps[Environment_1.OperatingSystems.WinRT] = /arm;/i;
osRegExps[Environment_1.OperatingSystems.Win] = /(windows|win32)/i;
osRegExps[Environment_1.OperatingSystems.iOS] = /(iPad|iPhone|iPod)/i;
osRegExps[Environment_1.OperatingSystems.MacOS] = /(macintosh|mac os x)/i;
osRegExps[Environment_1.OperatingSystems.Android] = /android/i;
osRegExps[Environment_1.OperatingSystems.ChromeOS] = /CrOS/i;
osRegExps[Environment_1.OperatingSystems.Linux] = /(linux|x11)/i;
const mobileRegExp = /(android|ipod|windows phone|wpdesktop|windows ce|blackberry\w*|meego|webos|palm|symbian|pda|\w*?mobile\w*?|\w*?phone\w*?)/i;
const tabletRegExp = /tablet|ipad/i;
const windows10RegExp = /Windows NT 10/i;
const windows81RegExp = /Windows NT 6\.3/i;
const windows8RegExp = /Windows NT 6\.2/i;
const windows7RegExp = /(Windows NT 6\.1)|(WOW64)/i;
const isEdgeAnaheim = /Edg\//i; // should be used only for showing different visuals, not for feature set distinguishing as it behaves as Chrome
const macIntelPlatformRegexp = /(macIntel|iPad|iPhone)/i;
const macRegexp = /(macintosh|mac os x)/i;
const iPADRendererWebGL = /Apple/i; // can be "Apple A12X GPU" or "Apple GPU" depending on iOS version.
const iPADVendorWebGL = "Apple Inc.";
const T1_SAFARI_SUPPORTED_MAJOR_VERSION = 13;
const T1_SAFARI_SUPPORTED_MINOR_VERSION = 1;
const T1_FIREFOX_SUPPORTED_VERSION = 60;
class EnvironmentService {
    constructor() {
        this.getUserAgent = () => window.navigator.userAgent;
        this.getOS = (userAgent = window.navigator.userAgent) => {
            let osName;
            for (osName in Environment_1.OperatingSystems) {
                if (Object.prototype.hasOwnProperty.call(Environment_1.OperatingSystems, osName)) {
                    const regexp = osRegExps[Environment_1.OperatingSystems[osName]];
                    if (regexp && userAgent.match(regexp)) {
                        if (Environment_1.OperatingSystems[osName] === Environment_1.OperatingSystems.MacOS) {
                            if (this.isDeviceIPad()) {
                                return Environment_1.OperatingSystems[Environment_1.OperatingSystems.iOS];
                            }
                        }
                        return Environment_1.OperatingSystems[osName];
                    }
                }
            }
            return Environment_1.OperatingSystems.Unknown;
        };
        this.getCanonicalOS = (userAgent = window.navigator.userAgent) => {
            const os = this.getOS(userAgent);
            switch (os) {
                case Environment_1.OperatingSystems.Win:
                    return Environment_1.CanonicalOperatingSystems.Win;
                case Environment_1.OperatingSystems.MacOS:
                    return Environment_1.CanonicalOperatingSystems.Mac;
                case Environment_1.OperatingSystems.Linux:
                    return Environment_1.CanonicalOperatingSystems.Linux;
                default:
                    return Environment_1.CanonicalOperatingSystems.Other;
            }
        };
        this.getDeviceType = (userAgent = window.navigator.userAgent) => {
            if (userAgent.match(tabletRegExp)) {
                return Environment_1.DeviceTypes.Tablet;
            }
            if (userAgent.match(mobileRegExp)) {
                return Environment_1.DeviceTypes.Mobile;
            }
            if (userAgent.match(macRegexp) && this.isDeviceIPad()) {
                return Environment_1.DeviceTypes.Tablet;
            }
            return Environment_1.DeviceTypes.Desktop;
        };
        this.getBrowserName = (userAgent = window.navigator.userAgent) => {
            let browserName;
            for (browserName in Environment_1.Browsers) {
                if (userAgent.match(new RegExp(Environment_1.Browsers[browserName]))) {
                    return Environment_1.Browsers[browserName];
                }
            }
            return Environment_1.Browsers.Unknown;
        };
        this.getBuildEnvironment = () => ServerParamsService_1.default.getServerParams().environment;
        this.isWindows10 = (userAgent = window.navigator.userAgent) => windows10RegExp.test(userAgent);
        this.isEdgeAnaheim = (userAgent = window.navigator.userAgent) => isEdgeAnaheim.test(userAgent);
        this.isArchitecturex64 = (userAgent = window.navigator.userAgent) => userAgent.indexOf("Win64") !== -1 ||
            userAgent.indexOf("x64") !== -1 ||
            userAgent.indexOf("WOW64") !== -1 ||
            userAgent.indexOf("x86_64") !== -1;
        this.isNativeClientSupportedOS = (userAgent = window.navigator.userAgent) => {
            let supported = !ConfigurationService_1.configurationService
                .getConfig()
                .nativeClientUnsupportedOS.some(os => os === this.getOS(userAgent));
            if (this.getCanonicalOS() === Environment_1.CanonicalOperatingSystems.Win &&
                !(windows10RegExp.test(userAgent) ||
                    windows8RegExp.test(userAgent) ||
                    windows7RegExp.test(userAgent) ||
                    windows81RegExp.test(userAgent))) {
                supported = false;
            }
            return supported;
        };
        this.isWebClientSupportedBrowser = (userAgent = window.navigator.userAgent) => {
            const browserName = this.getBrowserName(userAgent);
            let supported = ConfigurationService_1.configurationService
                .getConfig()
                .webClientSupportedBrowsers.some(browser => browser === browserName);
            // Refinement of Safari and Firefox support
            // Note: TFL is fully on T21 Web and the teams web client browser support is defined in life.jsonc
            // - https://domoreexp.visualstudio.com/Teamspace/_git/teams-modular-packages?path=/serve-configs/react-web-client/life.jsonc&version=GBmaster&_a=contents
            // - Keeping it in sync with JL FFs -> minT21Supported* and serving latest values via ECS
            if (browserName === Environment_1.Browsers.Safari) {
                supported = this.isTflEnvironment()
                    ? this.isSafariVersionFullySupported(this.flags.minT21SupportedSafariVersion, 0)
                    : this.isSafariVersionFullySupported(T1_SAFARI_SUPPORTED_MAJOR_VERSION, T1_SAFARI_SUPPORTED_MINOR_VERSION);
            }
            if (browserName === Environment_1.Browsers.Firefox) {
                supported = this.isTflEnvironment()
                    ? this.isFirefoxVersionFullySupported(this.flags.minT21SupportedFirefoxVersion)
                    : this.isFirefoxVersionFullySupported(T1_FIREFOX_SUPPORTED_VERSION);
            }
            return supported;
        };
        this.getLauncherVersion = () => {
            const v2Prefix = "LauncherV2";
            if (typeof LAUNCHER_VERSION === "string" && LAUNCHER_VERSION) {
                return `${v2Prefix}-${LAUNCHER_VERSION}`;
            }
            return v2Prefix;
        };
        /*
         * Function that returns a true if the UNMASKED_RENDERER_WEBGL and UNMASKED_VENDOR_WEBGL
         * is from Apple.
         */
        this.isWebGLVendorAndRendererApple = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
            if (context && "getExtension" in context) {
                const info = context.getExtension("WEBGL_debug_renderer_info");
                if (info && "getParameter" in context) {
                    if (context.getParameter(info.UNMASKED_RENDERER_WEBGL).match(iPADRendererWebGL) &&
                        context.getParameter(info.UNMASKED_VENDOR_WEBGL) === iPADVendorWebGL) {
                        return true;
                    }
                }
            }
            return false;
        };
        /*
         * As of iOS 13, safari on iPAD reports the same user-agent as Safari on desktop MacOS.
         * We try to figure out the platform and touch-screen-points to check if it is a
         * 1)apple device - macIntel platform & 2)touch screen & 3) has Apple GPU ? then it MUST be an iPAD.
         */
        this.isDeviceIPad = () => {
            if (this.getPlatform().match(macIntelPlatformRegexp) &&
                this.getMaxTouchPoints() &&
                this.getMaxTouchPoints() > 1 &&
                this.isWebGLVendorAndRendererApple()) {
                return true;
            }
            return false;
        };
        this.getEnvironmentInfo = () => ({
            userAgent: this.getUserAgent(),
            os: this.getOS(),
            canonicalOs: this.getCanonicalOS(),
            deviceType: this.getDeviceType(),
            browserName: this.getBrowserName(),
            browserMajorVersion: this.getBrowserMajorVersion(),
            buildEnvironment: this.getBuildEnvironment(),
            architectureType: this.isArchitecturex64() ? Environment_1.ArchitectureTypes.x64 : Environment_1.ArchitectureTypes.Other,
            isWebClientSupportedBrowser: this.isWebClientSupportedBrowser(),
            isNativeClientSupportedOS: this.isNativeClientSupportedOS(),
            isWindows10: this.isWindows10(),
            isWindows11: false,
            isEdgeAnaheim: this.isEdgeAnaheim(),
            isTflEnvironment: this.isTflEnvironment(),
            isDgjEnvironment: this.isDgjEnvironment(),
            isRoomOSEnvironment: this.isRoomOSEnvironment(),
            ring: ServerParamsService_1.default.getServerParams().ring,
            deviceId: OneDsSerivce_1.OneDS.getDeviceID(),
        });
        this.getPlatform = () => window.navigator.platform;
        this.getMaxTouchPoints = () => window.navigator.maxTouchPoints;
        this.getChromiumVersion = () => {
            var _a;
            const browserName = this.getBrowserName();
            const versionArr = navigator.userAgent.split(`${browserName}/`);
            if (versionArr.length < 2) {
                return 0;
            }
            const version = versionArr[1].substring(0, 3);
            return (_a = Utils_1.Utils.parseNumber(version)) !== null && _a !== void 0 ? _a : 0;
        };
        this.isFirefoxVersionFullySupported = (minMajorVersion) => {
            if (this.getBrowserName() !== Environment_1.Browsers.Firefox) {
                return false;
            }
            const firefoxVersion = this.getChromiumVersion();
            if ((0, lodash_1.isUndefined)(firefoxVersion)) {
                return false;
            }
            // TFL doesn't need to rely on old minSupportedFirefoxVersion, it passes down T21 firefox version into minMajorVersion
            // Keeping this block for TFW as they pass down constant T1 minMajorVersion: 60, but have value 96 in minSupportedFirefoxVersion,
            // so removing it would enable browser on version from 60-96 which may not be OK at this time
            if (!this.isTflEnvironment()) {
                const minSupportedFirefoxVersion = Utils_1.Utils.parseNumber(this.flags.minSupportedFirefoxVersion);
                if ((0, lodash_1.isUndefined)(minSupportedFirefoxVersion)) {
                    return false;
                }
                return firefoxVersion >= minSupportedFirefoxVersion && firefoxVersion >= minMajorVersion;
            }
            return firefoxVersion >= minMajorVersion;
        };
        this.getSafariVersion = () => {
            const version = navigator.appVersion.match(/Version\/([0-9]+)\.([0-9]+)/);
            if (version && version.length > 1) {
                return [parseInt(version[1], 10), parseInt(version[2], 10)];
            }
            return [0, 0];
        };
        this.isSafariVersionFullySupported = (minMajorVersion, minMinorVersion) => {
            // version = ["Version/14.0", "14", "0"];
            const [majorVersion, minorVersion] = this.getSafariVersion();
            if (majorVersion > minMajorVersion ||
                (minMinorVersion !== undefined &&
                    majorVersion === minMajorVersion &&
                    minorVersion >= minMinorVersion)) {
                return true;
            }
            return false;
        };
        this.isDgjEnvironment = () => {
            const userAgent = this.getUserAgent();
            return ConfigurationService_1.configurationService
                .getConfig()
                .dgjSupportedUserAgents.some(userAgentSubstring => userAgent.includes(userAgentSubstring));
        };
        this.isRoomOSEnvironment = () => {
            const userAgent = this.getUserAgent();
            return userAgent.includes("RoomOS");
        };
        this.isTflEnvironment = () => this.getBuildEnvironment() === Environment_1.BuildEnvironment.Life;
    }
    init() {
        this._info = this.getEnvironmentInfo();
        return this.getHighEntropyPlatformVersion()
            .then(platformVersion => {
            // Storing original version for logger context adjustments
            this._info.highEntropyPlatformVersion = platformVersion;
            this._info.isWindows10 =
                this._info.isWindows10 || this.evaluateIsWindows11(platformVersion);
            this._info.isWindows11 = this.evaluateIsWindows11(platformVersion);
            this._info.isNativeClientSupportedOS =
                this.evaluateNativeClientFromHighEntropy(platformVersion);
            return this._info;
        })
            .catch(_ => this._info);
    }
    get flags() {
        return FeaturesService_1.default.getFeatureFlags();
    }
    getBrowserMajorVersion(userAgent = window.navigator.userAgent) {
        const browserName = this.getBrowserName(userAgent);
        switch (browserName) {
            case Environment_1.Browsers.Safari:
                return this.getSafariVersion()[0];
            default:
                return this.getChromiumVersion();
        }
    }
    getScreenResolution(screen = window.screen) {
        if (screen.width == null ||
            typeof screen.width === "undefined" ||
            screen.height == null ||
            typeof screen.height === "undefined") {
            return "Unknown";
        }
        return `${screen.width}x${screen.height}`;
    }
    evaluateIsWindows11(highEntropyPlatformVersion) {
        if (this.getOS() === Environment_1.OperatingSystems.Win) {
            const majorPlatformVersion = parseInt(highEntropyPlatformVersion.split(".")[0]);
            return majorPlatformVersion >= 13;
        }
        return false;
    }
    getHighEntropyPlatformVersion() {
        const uad = window.navigator.userAgentData;
        if (uad) {
            return uad.getHighEntropyValues(["platformVersion"]).then((ua) => {
                if (ua.platformVersion) {
                    return ua.platformVersion;
                }
                return Promise.reject();
            });
        }
        return Promise.reject();
    }
    evaluateNativeClientFromHighEntropy(platformVersion) {
        let supported = !ConfigurationService_1.configurationService
            .getConfig()
            .nativeClientUnsupportedOS.some(os => os === this.getOS(this.getUserAgent()));
        // https://github.com/WICG/ua-client-hints/issues/220#issuecomment-870858413
        if (this.getCanonicalOS() === Environment_1.CanonicalOperatingSystems.Win) {
            const majorPlatformVersion = parseInt(platformVersion.split(".")[0]);
            const minorPlatformVersion = parseInt(platformVersion.split(".")[1]);
            supported =
                majorPlatformVersion >= 1 || (majorPlatformVersion === 0 && minorPlatformVersion !== 0);
        }
        return supported;
    }
}
const environmentService = new EnvironmentService();
exports.default = environmentService;
