"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConfig_1 = require("../BaseConfig");
const Environment_1 = require("../../models/Environment");
const cypressConfig = {
    env: Environment_1.BuildEnvironment.Cypress,
    ecs: Object.assign(Object.assign({}, BaseConfig_1.default.ecs), { host: "" }),
    apis: {
        desktopInstaller: "/mockDownloads",
        desktopContextualInstaller: "/mockDownloadsInstaller",
    },
    urls: Object.assign(Object.assign({}, BaseConfig_1.default.urls), { absoluteUrls: Object.assign(Object.assign({}, BaseConfig_1.default.urls.absoluteUrls), { brandingService: "http://localhost:9000/api/mt/beta/meetings/branding", brandingWithMeetingidService: "http://localhost:9000/api/mt/beta/meetings/brandinginfo" }) }),
};
exports.default = cypressConfig;
