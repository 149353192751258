"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Launcher = void 0;
const React = require("react");
const UrlHelper_1 = require("../../helpers/UrlHelper");
const Environment_1 = require("../../models/Environment");
const LaunchType_1 = require("../../models/LaunchType");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const FeaturesService_1 = require("../../services/FeaturesService");
const LauncherService_1 = require("../../services/LauncherService");
const Logger_1 = require("../../services/Logger");
const SmartiOSBannerService_1 = require("../../services/SmartiOSBannerService");
const Utils_1 = require("../../helpers/Utils");
class Launcher extends React.PureComponent {
    constructor(props) {
        super(props);
        this.launcherService = this.props.launcherService;
        this.state = {
            launchUrl: this.launcherService.getLaunchUrl(props.launchType),
            launchMethod: this.launcherService.getLaunchMethod(),
            autoJoinWebBehaviour: this.launcherService.getAutoJoinWebBehaviour(),
        };
    }
    componentDidMount() {
        if (this.state.autoJoinWebBehaviour.shouldAutoJoinWeb) {
            this.launcherService.redirectToWebJoin(this.state.autoJoinWebBehaviour.reason);
            this.props.launchResultCallback(LauncherService_1.LaunchResults.NotAttempted);
            return;
        }
        if (!this.props.trigger) {
            const browserName = this.props.environment.browserName;
            let result = LauncherService_1.LaunchResults.NotAttempted;
            // When we have a flag that previous attempt to start Desktop was successful (which we have on Edge only, we can show UI as it happen also now)
            if (FeaturesService_1.default.getFeatureFlags().promptSuccess && browserName === Environment_1.Browsers.Edge) {
                result = LauncherService_1.LaunchResults.PreviouslySucceeded;
            }
            this.props.launchResultCallback(result);
            return;
        }
        this._preventAutoLaunchOnRefresh(this.props.featureFlags.suppressPrompt);
        if (SmartiOSBannerService_1.default.shouldShowSmartBanner() &&
            SmartiOSBannerService_1.default.isIosOnSafari() &&
            FeaturesService_1.default.getFeatureFlags().enableMobilePage &&
            this.props.isMobileModernView) {
            return;
        }
        switch (this.state.launchMethod) {
            case LauncherService_1.LaunchMethods.Url:
                this._triggerUrlLaunch();
                break;
            case LauncherService_1.LaunchMethods.MsLaunch:
                this._triggerMsLaunch();
                break;
            case LauncherService_1.LaunchMethods.None:
                this._reportProtocolLaunch(LauncherService_1.LaunchResults.NotLaunched);
                break;
            case LauncherService_1.LaunchMethods.Iframe:
                this._reportProtocolLaunch(LauncherService_1.LaunchResults.Unhandled);
        }
    }
    render() {
        if (!this.props.trigger ||
            this.state.autoJoinWebBehaviour.shouldAutoJoinWeb ||
            this.state.launchMethod !== LauncherService_1.LaunchMethods.Iframe) {
            return null;
        }
        return (React.createElement("iframe", { id: "teamsLauncher", style: { display: "none" }, src: this.state.launchUrl, sandbox: "allow-top-navigation-to-custom-protocols" }));
    }
    _triggerUrlLaunch() {
        this._reportProtocolLaunch(LauncherService_1.LaunchResults.Unhandled);
        const redirectUrl = this.launcherService.getRedirectUrlForIos();
        UrlHelper_1.UrlHelper.redirect(this.state.launchUrl);
        if (redirectUrl) {
            UrlHelper_1.UrlHelper.redirect(redirectUrl);
            return;
        }
    }
    _triggerMsLaunch() {
        const timeout = window.setTimeout(() => {
            this._reportProtocolLaunch(LauncherService_1.LaunchResults.UserCancelled);
        }, 2000);
        window.navigator.msLaunchUri(this.state.launchUrl, () => {
            window.clearTimeout(timeout);
            this._reportProtocolLaunch(LauncherService_1.LaunchResults.Success);
            this._storeSuccessAutoLaunch();
        }, () => {
            window.clearTimeout(timeout);
            this._reportProtocolLaunch(LauncherService_1.LaunchResults.NoHandler);
        });
    }
    _reportProtocolLaunch(launchResult) {
        Utils_1.Utils.protocolDetectionSequence().then(({ isDetected, appHasFocus }) => {
            const event = new TelemetryEvents_1.ProtocolLaunchEvent({
                launchMethod: this.state.launchMethod,
                launchResult,
                launchType: LaunchType_1.LaunchType[this.props.launchType],
                pushStateAvailable: !!history.pushState,
                suppressPrompt: FeaturesService_1.default.getFeatureFlags().suppressPrompt,
                appHasFocus,
                isProtocolDetected: isDetected,
            });
            Logger_1.default.log(event);
            this.props.launchResultCallback(launchResult);
        });
    }
    _preventAutoLaunchOnRefresh(isSuppressed) {
        var _a, _b;
        if (isSuppressed) {
            return;
        }
        // Avoiding unwanted "autoLaunch" when browser refresh the tab, recover from previously killed session, etc..
        const suppressedPromptUrl = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(window.location.href, "suppressPrompt", "true");
        // for browsers not supporting history.pushState (IE9) we don't suppress the prompt
        // Appending QSP without refresh
        (_b = (_a = window.history).pushState) === null || _b === void 0 ? void 0 : _b.call(_a, {}, "", suppressedPromptUrl);
    }
    // Stores a state of protocol launch in Edge
    _storeSuccessAutoLaunch() {
        var _a, _b;
        const suppressedPromptUrl = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(window.location.href, "promptSuccess", "true");
        // for browsers not supporting history.pushState (IE9) we don't suppress the prompt
        // Appending QSP without refresh
        (_b = (_a = window.history).pushState) === null || _b === void 0 ? void 0 : _b.call(_a, {}, "", suppressedPromptUrl);
    }
}
exports.Launcher = Launcher;
