"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildLightMeetingsUrl = void 0;
const buildLightMeetingsUrl = (joinInfo, container, buildVersion, deeplinkId) => {
    const url = new URL("https://teams.microsoft.com/light-meetings/launch");
    url.searchParams.append("agent", "web");
    container && url.searchParams.append("container", container);
    buildVersion && url.searchParams.append("version", buildVersion);
    url.searchParams.append("coords", btoa(JSON.stringify(joinInfo)));
    deeplinkId && url.searchParams.append("deeplinkId", deeplinkId);
    return url.toString();
};
exports.buildLightMeetingsUrl = buildLightMeetingsUrl;
