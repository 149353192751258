"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const React = require("react");
const ButtonsFactory_1 = require("../../../helpers/ButtonsFactory");
const Translate_1 = require("../Translate");
require("./Button.scss");
const teamsLogoImg = require("../../../images/v2/logo_teams.svg");
const tflLogoWin11Img = require("../../../images/v3/tfl_win11_logo.svg");
const TranslationService_1 = require("../../../services/TranslationService");
const CoreContext_1 = require("../../../contexts/CoreContext");
class Button extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment, featureFlags }) => {
            const { type, title, ariaLabel } = this.props;
            if (this.isJoinButton(this.props)) {
                return (React.createElement("button", { "aria-label": this.getAriaLabelTranslation(ariaLabel), className: `btn ${type === ButtonsFactory_1.ButtonType.Primary ? "primary" : "secondary"} `, onClick: this.onClickHandler.bind(this), "data-tid": this.props["data-tid"] }, this.getJoinActionsButtonContent(title)));
            }
            else {
                return (React.createElement("div", { className: "secondaryAction" },
                    this.getImage(environment, featureFlags),
                    this.getSecondaryActionButtonContent(title, ariaLabel, this.props)));
            }
        }));
    }
    getJoinActionsButtonContent(title) {
        return (React.createElement("div", { className: "text" },
            React.createElement("h3", null,
                React.createElement(Translate_1.Translate, { i18n: title }))));
    }
    getSecondaryActionButtonContent(title, ariaLabel, props) {
        return (React.createElement("div", { className: "text" },
            React.createElement("p", null,
                React.createElement(Translate_1.Translate, { i18n: title }),
                React.createElement("br", null),
                React.createElement("a", { href: "#", onClick: e => this.onClickHandler(e), "data-tid": this.props["data-tid"], "aria-label": this.getAriaLabelTranslation(ariaLabel) },
                    React.createElement(Translate_1.Translate, { i18n: props.text })))));
    }
    getImage(env, featureFlags) {
        const logoSource = env.isTflEnvironment && env.isWindows11 && !featureFlags.enableTflWin11DefaultTeamsLogo
            ? tflLogoWin11Img
            : teamsLogoImg;
        return React.createElement("img", { src: logoSource, className: "img", "aria-hidden": true });
    }
    onClickHandler(event) {
        event.preventDefault();
        this.props.onClick();
    }
    getAriaLabelTranslation(ariaLabel) {
        return TranslationService_1.default.translate(ariaLabel);
    }
    isJoinButton(btn) {
        return btn.type === ButtonsFactory_1.ButtonType.Primary || btn.type === ButtonsFactory_1.ButtonType.Secondary;
    }
}
exports.Button = Button;
