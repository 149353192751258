"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinType = void 0;
var JoinType;
(function (JoinType) {
    JoinType["Meetup"] = "meetup-join";
    JoinType["Call"] = "call";
    JoinType["FremiumMeeting"] = "freemium-meeting";
    // TFL 1-way redeem link
    JoinType["TflInvite"] = "invite";
    // TFL 2-way claim link
    JoinType["TflClaim"] = "claim";
    // TFL marketing funnel
    JoinType["TflFunnel"] = "funnel";
    // Meeting join with code TFL & TFW
    JoinType["MeetupWithCode"] = "meet";
    // Share to Teams
    JoinType["ShareEmail"] = "share-email";
    // Contact Syncing
    JoinType["TflContactSync"] = "contact-sync";
    // TFL Community Join
    JoinType["TflCommunity"] = "community";
    // Message post
    JoinType["Message"] = "message";
    // Teams Insider - TFL only
    JoinType["TeamsInsider"] = "teamsInsider";
    // School Connection - TFL only
    JoinType["SchoolConnection"] = "schoolConnection";
    // there are way more types, but we don't do anything specific for others
})(JoinType = exports.JoinType || (exports.JoinType = {}));
