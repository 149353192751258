"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const FeaturesService_1 = require("./FeaturesService");
const Utils_1 = require("../helpers/Utils");
const Environment_1 = require("../models/Environment");
const EnvironmentService_1 = require("./EnvironmentService");
const ConfigurationService_1 = require("./ConfigurationService");
class SmartiOSBannerService {
    constructor() {
        this.COOKIE_NAME = "hasVisitedJL";
        this.sevenDaysInMilliseconds = 604800000; // 7 * 24 * 60 * 60 * 1000
    }
    shouldShowSmartBanner() {
        return (FeaturesService_1.default &&
            FeaturesService_1.default.getFeatureFlags().showiOSSmartBannerOnFirstLaunch &&
            !this.cookieExists());
    }
    showSmartBanner(launchUrl) {
        const meta = document.createElement("meta");
        meta.name = "apple-itunes-app";
        meta.content = `app-id=${ConfigurationService_1.configurationService.getConfig().appStores.apple.appID}, app-argument=${launchUrl}`;
        document.head.appendChild(meta);
    }
    markAsSeen() {
        const date = new Date(Date.now() + this.sevenDaysInMilliseconds);
        Utils_1.Utils.setDocumentCookie(`${this.COOKIE_NAME}=true;expires=${date.toUTCString()};path=/;`);
    }
    isIosOnSafari() {
        return (EnvironmentService_1.default.getOS() === Environment_1.OperatingSystems.iOS &&
            EnvironmentService_1.default.getBrowserName() === Environment_1.Browsers.Safari &&
            (EnvironmentService_1.default.getDeviceType() === Environment_1.DeviceTypes.Mobile ||
                EnvironmentService_1.default.getDeviceType() === Environment_1.DeviceTypes.Tablet));
    }
    cookieExists() {
        return !!Utils_1.Utils.returnDocumentCookie()
            .split("; ")
            .find(q => q.indexOf(`${this.COOKIE_NAME}=`, 0) === 0); // exact cookie name match "name="
    }
}
const smartiOSBannerService = new SmartiOSBannerService();
exports.default = smartiOSBannerService;
