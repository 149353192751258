"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamsLogo = void 0;
const React = require("react");
const teamsLogoImg = require("../../images/logo.svg");
class TeamsLogo extends React.PureComponent {
    render() {
        const _a = this.props, { 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        children, src = teamsLogoImg, className = "" } = _a, props = __rest(_a, ["children", "src", "className"]);
        return React.createElement("img", Object.assign({}, props, { className: `logo ${className}`, src: src, "aria-hidden": true }));
    }
}
exports.TeamsLogo = TeamsLogo;
