"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.privacyStatementService = void 0;
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const ConfigurationService_1 = require("./ConfigurationService");
const FeaturesService_1 = require("./FeaturesService");
const Logger_1 = require("./Logger");
class PrivacyStatementService {
    constructor() {
        this.isFetchingPrivacyStatementForKey = null;
        this.ongoingRequest = null;
        this.urlCache = {};
    }
    fetchPrivacyStatement(privacyStatementInput) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const key = (_a = privacyStatementInput.meetingCode) !== null && _a !== void 0 ? _a : privacyStatementInput.tenantId;
            if (!key) {
                throw new Error("Insufficient information to fetch privacy statement. Meeting code and tenant ID cannot both be null.");
            }
            if (this.urlCache[key]) {
                return Promise.resolve(this.urlCache[key]);
            }
            if (this.ongoingRequest !== null && this.isFetchingPrivacyStatementForKey !== key) {
                throw new Error("Already fetching privacy statement from another tenant.");
            }
            if (this.ongoingRequest === null) {
                this.requestStartTime = Date.now();
                this.isFetchingPrivacyStatementForKey = key;
                const { joinUrl, tenantId, organizerId } = privacyStatementInput;
                this.ongoingRequest = this.fetchPrivacyStatementFromApi(joinUrl, key, tenantId, organizerId)
                    .then((url) => {
                    this.urlCache[key] = url;
                    return url;
                })
                    .catch(error => {
                    throw error;
                })
                    .finally(() => {
                    this.isFetchingPrivacyStatementForKey = null;
                    this.ongoingRequest = null;
                });
            }
            return this.ongoingRequest;
        });
    }
    clearCache() {
        this.urlCache = {};
    }
    getPrivacyStatementServiceUrl(joinUrl, tenantId, organizerId) {
        const baseUrl = ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.privacyStatementService;
        const queryParams = new URLSearchParams({ joinUrl });
        if (tenantId && organizerId) {
            queryParams.append("tenantId", tenantId);
            queryParams.append("organizerId", organizerId);
        }
        return `${baseUrl}?${queryParams.toString()}`;
    }
    fetchPrivacyStatementFromApi(joinUrl, key, tenantId, organizerId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let fetchResponse;
            let fetchResponseJson;
            try {
                fetchResponse = yield this.fetchWithTimeout(this.getPrivacyStatementServiceUrl(joinUrl, tenantId, organizerId), {
                    mode: "cors",
                    credentials: "omit",
                }, FeaturesService_1.default.getFeatureFlags().fetchOrganizationPrivacyStatementTimeout, key);
                fetchResponseJson = yield fetchResponse.json();
            }
            catch (error) {
                throw new Error(error === null || error === void 0 ? void 0 : error.message);
            }
            const xServerRequestId = (_a = fetchResponse.headers.get("x-serverrequestid")) !== null && _a !== void 0 ? _a : undefined;
            const xMsEdgeRef = (_b = fetchResponse.headers.get("X-MSEdge-Ref")) !== null && _b !== void 0 ? _b : undefined;
            if (!fetchResponse.ok) {
                Logger_1.default.log(new TelemetryEvents_1.PrivacyStatementEvent({
                    key,
                    status: "failure",
                    message: "Server error",
                    time: this.requestStartTime ? Date.now() - this.requestStartTime : undefined,
                    xServerRequestId,
                    xMsEdgeRef,
                }));
                throw new Error("Error fetching privacy statement, the request failed.");
            }
            Logger_1.default.log(new TelemetryEvents_1.PrivacyStatementEvent({
                key,
                status: "success",
                time: this.requestStartTime ? Date.now() - this.requestStartTime : undefined,
                xServerRequestId,
                xMsEdgeRef,
            }));
            return fetchResponseJson.statementUrl;
        });
    }
    fetchWithTimeout(url, options, timeout, key) {
        return __awaiter(this, void 0, void 0, function* () {
            let timeoutId;
            const fetchPromise = window
                .fetch(url, options)
                .then(res => res)
                .catch(e => {
                Logger_1.default.log(new TelemetryEvents_1.PrivacyStatementEvent({
                    key,
                    status: "failure",
                    message: "Fetch error",
                    error: e === null || e === void 0 ? void 0 : e.message,
                    time: this.requestStartTime ? Date.now() - this.requestStartTime : undefined,
                }));
                throw e;
            })
                .finally(() => {
                window.clearInterval(timeoutId);
            });
            const timeoutPromise = new Promise((_, reject) => {
                timeoutId = window.setTimeout(() => {
                    Logger_1.default.log(new TelemetryEvents_1.PrivacyStatementEvent({
                        key,
                        status: "failure",
                        message: "Fetch timeout",
                        time: this.requestStartTime ? Date.now() - this.requestStartTime : undefined,
                    }));
                    reject(new Error("Fetch timeout"));
                }, timeout);
            });
            return Promise.race([timeoutPromise, fetchPromise]);
        });
    }
}
exports.privacyStatementService = new PrivacyStatementService();
