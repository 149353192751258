"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip = void 0;
const React = require("react");
require("./tooltip.scss");
const Translate_1 = require("./Translate");
class Tooltip extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        };
    }
    render() {
        const { text, children } = this.props;
        return (React.createElement("div", { tabIndex: 0, className: "tooltip-trigger", onMouseEnter: () => this.setState({ isVisible: true }), onMouseLeave: () => this.setState({ isVisible: false }), onFocus: () => this.setState({ isVisible: true }), onBlur: () => this.setState({ isVisible: false }), "aria-describedby": "tooltip" },
            children,
            this.state.isVisible ? (React.createElement("div", { className: `tooltip`, id: "tooltip", role: "tooltip", "aria-atomic": "true", "aria-live": "polite" },
                React.createElement(Translate_1.Translate, { i18n: text }))) : null));
    }
}
exports.Tooltip = Tooltip;
