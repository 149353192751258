"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateHelper = void 0;
class StateHelper {
    static getStateValue(joinInfo) {
        const stateValue = decodeURIComponent(joinInfo.deepLinkUrl);
        let urlState;
        // Determine which the state of the deeplink, which will tell us which Teams app the user came from
        if (stateValue.indexOf("Calendar") !== -1) {
            urlState = "Calendar";
        }
        else if (stateValue.indexOf("Contacts") !== -1) {
            urlState = "Contacts";
        }
        else {
            // need a default case here
            urlState = undefined;
        }
        return urlState;
    }
    static isGoogleAuth(joinInfo) {
        const deeplinkUrl = joinInfo.deepLinkUrl;
        const isAuth = deeplinkUrl.indexOf("auth");
        if (isAuth > -1) {
            return deeplinkUrl.indexOf("google") > -1;
        }
        else {
            return false;
        }
    }
}
exports.StateHelper = StateHelper;
