"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
class PromisesHelper {
    static timeout(promise, timeoutMs) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error("timed out"));
            }, timeoutMs);
            promise.then(resolve, reject);
        });
    }
    static resolved(value) {
        return new Promise(resolve => {
            resolve(value);
        });
    }
    static createDefered() {
        let resolve = lodash_1.noop;
        let reject = lodash_1.noop;
        const promise = new Promise((_resolve, _reject) => {
            resolve = (result) => _resolve(result);
            reject = (error) => _reject(error);
        });
        return {
            resolve,
            reject,
            promise,
        };
    }
}
exports.default = PromisesHelper;
