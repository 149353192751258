"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LauncherService = exports.tflLaunchTypes = exports.AUTHENTICATED_WEB_REDIRECT_ROUTE = exports.AutoJoinWebReasons = exports.LaunchResults = exports.LaunchMethods = void 0;
const UrlHelper_1 = require("../helpers/UrlHelper");
const Environment_1 = require("../models/Environment");
const JoinInfo_1 = require("../models/JoinInfo");
const LaunchType_1 = require("../models/LaunchType");
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const Logger_1 = require("./Logger");
const AutoDownloader_1 = require("../components/join-launcher/AutoDownloader");
const ConfigurationService_1 = require("./ConfigurationService");
const LightMeetingsHelper_1 = require("../helpers/LightMeetingsHelper");
const ServerParamsService_1 = require("./ServerParamsService");
const CommunityEventsHelper_1 = require("../helpers/CommunityEventsHelper");
const Utils_1 = require("../helpers/Utils");
var LaunchMethods;
(function (LaunchMethods) {
    LaunchMethods["Iframe"] = "iframe";
    LaunchMethods["Url"] = "url";
    LaunchMethods["MsLaunch"] = "msLaunchUri";
    LaunchMethods["None"] = "";
})(LaunchMethods = exports.LaunchMethods || (exports.LaunchMethods = {}));
var LaunchResults;
(function (LaunchResults) {
    LaunchResults["Success"] = "success";
    LaunchResults["PreviouslySucceeded"] = "previouslySucceeded";
    LaunchResults["NoHandler"] = "noHandler";
    LaunchResults["Unhandled"] = "unhandled";
    LaunchResults["UserCancelled"] = "userCancelled";
    LaunchResults["NotLaunched"] = "notLaunched";
    LaunchResults["NotAttempted"] = "notAttempted";
})(LaunchResults = exports.LaunchResults || (exports.LaunchResults = {}));
var AutoJoinWebReasons;
(function (AutoJoinWebReasons) {
    AutoJoinWebReasons["UnsupportedOS"] = "unSupportedOS";
    AutoJoinWebReasons["UrlParameter"] = "urlParameter";
    AutoJoinWebReasons["ContinueToWebAsOnlyOption"] = "continueToWebAsOnlyOption";
    /**
     * Triggered from a DGJ device, which expects to land automatically on
     * the pre-join screen. More information on DGJ:
     * https://learn.microsoft.com/en-us/microsoftteams/rooms/third-party-join
     */
    AutoJoinWebReasons["LightMeetingsExperienceForDGJ"] = "lightMeetingsExperienceForDGJ";
    /**
     * Triggered from another experience that has resolved to redirect to LIME
     * for a better experience. Example: T2.1 after detecting an anonymous user.
     */
    AutoJoinWebReasons["ForcedLightMeetingsExperienceForWeb"] = "forcedLightMeetingsExperienceForWeb";
    AutoJoinWebReasons["RedirectFromPDS"] = "redirectFromPDS";
})(AutoJoinWebReasons = exports.AutoJoinWebReasons || (exports.AutoJoinWebReasons = {}));
exports.AUTHENTICATED_WEB_REDIRECT_ROUTE = "/_#";
const nativeTswClientProtocol = "msteams:";
const nativeTflClientProtocol = "ms-teams:";
const launchAgentParamValue = "join_launcher";
const launchAgentContextualParamValue = "join_launcher_contextual";
const dogfoodRoute = "/dogfood";
const tflLaunchTypesExp = {
    [LaunchType_1.LaunchType.Automatic]: "jla",
    [LaunchType_1.LaunchType.WebApp]: "jlw",
    [LaunchType_1.LaunchType.OpenApp]: "jlo",
    [LaunchType_1.LaunchType.GoToStore]: "jls",
    [LaunchType_1.LaunchType.ViewDetails]: "jlvd",
};
/**
 * New mapping of LaunchType to human readable format for better tracking
 */
exports.tflLaunchTypes = {
    [LaunchType_1.LaunchType.Automatic]: "auto",
    [LaunchType_1.LaunchType.WebApp]: "web",
    [LaunchType_1.LaunchType.OpenApp]: "open",
    [LaunchType_1.LaunchType.GoToStore]: "store",
    [LaunchType_1.LaunchType.ViewDetails]: "view",
};
class LauncherService {
    constructor(environment, featureFlags, joinInfo) {
        this.environment = environment;
        this.featureFlags = featureFlags;
        this.joinInfo = joinInfo;
        this.redirectToLearnMore = () => {
            const url = ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.learnMore;
            const event = new TelemetryEvents_1.OpenLearnMoreLink();
            Logger_1.default.log(event);
            UrlHelper_1.UrlHelper.openInTab(url);
        };
        this.hasAutoDownloadAttemptKey = !!window.localStorage.getItem(AutoDownloader_1.autoDownloadAttemptStorageKey);
    }
    getLaunchMethod() {
        if (this.isMsLaunchSupported()) {
            return LaunchMethods.MsLaunch;
        }
        // this avoids unwanted behavior on Firefox mobile when launch method is Url and app is not present
        if (this.environment.browserName === Environment_1.Browsers.Firefox) {
            return LaunchMethods.Iframe;
        }
        switch (this.environment.os) {
            case Environment_1.OperatingSystems.ChromeOS:
                return LaunchMethods.None;
            case Environment_1.OperatingSystems.iOS:
            case Environment_1.OperatingSystems.Android:
                return LaunchMethods.Url;
        }
        return LaunchMethods.Iframe;
    }
    getLaunchUrl(launchType) {
        if (this.featureFlags.enableFQDN) {
            return this.getProtocolLaunchDeeplinkUrl(launchType);
        }
        return this.getClientLaunchUrl(launchType);
    }
    getProtocolLaunchDeeplinkUrl(launchType) {
        let launchUri = this.getClientLaunchUrl(launchType);
        let queryString = UrlHelper_1.UrlHelper.getQueryString();
        queryString = UrlHelper_1.UrlHelper.removeQueryParameter(queryString, "url");
        queryString = UrlHelper_1.UrlHelper.removeQueryParameter(queryString, "deeplinkId");
        const queryStringParamsOnly = queryString.startsWith("?") ? queryString.substr(1) : queryString;
        if (queryStringParamsOnly.length > 0) {
            launchUri += `&${queryStringParamsOnly}`;
        }
        launchUri = UrlHelper_1.UrlHelper.addQueryParameter(launchUri, "fqdn", window.location.hostname, false);
        return launchUri;
    }
    getClientLaunchUrl(launchType) {
        let uri = this.getUrlWithLaunchTypeInfo(this.joinInfo.deepLinkUrl, launchType);
        // mobile apps implemented protocol handler in same way as handling universal links http://domain/deeplink,
        // so we are appending domain before deeplink.
        if (this.environment.os === Environment_1.OperatingSystems.WinPhone ||
            this.environment.os === Environment_1.OperatingSystems.Android ||
            this.environment.os === Environment_1.OperatingSystems.iOS) {
            uri = `//${window.location.hostname}${uri}`;
        }
        if (this.environment.isTflEnvironment &&
            this.environment.os === Environment_1.OperatingSystems.Win &&
            this.featureFlags.enableTflClientProtocol) {
            uri = nativeTflClientProtocol + uri;
        }
        else {
            uri = nativeTswClientProtocol + uri;
        }
        uri = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(uri, "deeplinkId", this.joinInfo.deepLinkIds.client, false);
        uri = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(uri, "launchAgent", this.joinInfo.launchAgent ? this.joinInfo.launchAgent : launchAgentParamValue, false);
        return uri;
    }
    getContextualInstallDeeplinkUrl(launchType) {
        let deepLinkUrl = this.getUrlWithLaunchTypeInfo(this.joinInfo.deepLinkUrl, launchType);
        let queryString = UrlHelper_1.UrlHelper.getQueryString();
        queryString = UrlHelper_1.UrlHelper.removeQueryParameter(queryString, "url");
        const queryStringParamsOnly = queryString.startsWith("?") ? queryString.substr(1) : queryString;
        if (queryStringParamsOnly.length > 0) {
            deepLinkUrl += `&${queryStringParamsOnly}`;
        }
        deepLinkUrl = UrlHelper_1.UrlHelper.addQueryParameter(deepLinkUrl, "fqdn", window.location.hostname, false);
        deepLinkUrl = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(deepLinkUrl, "launchAgent", launchAgentContextualParamValue, false);
        deepLinkUrl = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(deepLinkUrl, "deeplinkId", this.joinInfo.deepLinkIds.client, false);
        return deepLinkUrl;
    }
    getRedirectUrlForIos() {
        if (this.environment.os === Environment_1.OperatingSystems.iOS &&
            this.environment.browserName === Environment_1.Browsers.Safari &&
            !this.featureFlags.enableiOSJoinLauncherPage) {
            return ConfigurationService_1.configurationService.getConfig().urls.paths.download;
        }
        return;
    }
    getOpenInTeamsWebUrl(autoJoinReason) {
        var _a, _b, _c;
        if ((autoJoinReason === AutoJoinWebReasons.LightMeetingsExperienceForDGJ &&
            ((this.joinInfo.type === JoinInfo_1.JoinType.Meetup &&
                this.joinInfo.meetingInfo &&
                this.joinInfo.threadId) ||
                (this.joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode && this.joinInfo.code))) ||
            autoJoinReason === AutoJoinWebReasons.ForcedLightMeetingsExperienceForWeb ||
            this.shouldRedirectToLightMeetings()) {
            return this.getLightMeetingsUrl();
        }
        if (this.joinInfo.type === JoinInfo_1.JoinType.ShareEmail && this.joinInfo.shareEmailInfo) {
            return this.joinInfo.shareEmailInfo.webUrl;
        }
        // add broadcast attendee convene url path if it's broadcast role in deeplink is attendee
        const shouldUseConveneUrl = !!(this.featureFlags.enableBroadcastAttendeeConveneJoin &&
            ((_a = this.joinInfo.meetingInfo) === null || _a === void 0 ? void 0 : _a.isBroadcast) &&
            UrlHelper_1.UrlHelper.getQueryParameter("role", this.joinInfo.deepLinkUrl) === "a") && !(this.isT21WebMeetingJoinSupported() && this.featureFlags.enableT21WebRedirectForTLE);
        let url = UrlHelper_1.UrlHelper.getOrigin();
        if (this.isT21WebMeetingJoinSupported() && !shouldUseConveneUrl) {
            /**
             * always redirect to T2 and let T2 exp loader and RWC core tasks to determine redirect to T1 or no
             * appending a qsp of meetingjoin immediately after the /v2 so it can skip exp loader redirect logic
             */
            if (this.environment.isTflEnvironment) {
                // For TFL redirect to V2 only, without meetingjoin QSP:
                // - TFL is fully on T21 Web and we want to skip logic in anon-join-core-task
                // - the core task logic would redirect signed in users that didn't opted in for T2 back to T1
                // - the optIn flag for TFL is not being stamped by direct access to T2 because of potential auth loop between T1 and T2
                url += "/v2/";
            }
            else {
                url += "/v2/?meetingjoin=true";
            }
        }
        if (shouldUseConveneUrl) {
            url += `${ConfigurationService_1.configurationService.getConfig().urls.broadcast.convenePath}`;
            url = UrlHelper_1.UrlHelper.addQueryParameter(url, "url", this.joinInfo.deepLinkUrl, true);
            // tenantId is required for anonymous attendee joins
            url = UrlHelper_1.UrlHelper.addQueryParameter(url, "tenantId", ((_b = this.joinInfo.meetingInfo) === null || _b === void 0 ? void 0 : _b.tenantId) || "", false);
        }
        if (this.joinInfo.ring) {
            url = UrlHelper_1.UrlHelper.addQueryParameter(url, "ring", this.joinInfo.ring, false);
        }
        if (this.joinInfo.guestAccessTenantId) {
            url = UrlHelper_1.UrlHelper.addQueryParameter(url, "tenantId", this.joinInfo.guestAccessTenantId, false);
        }
        else if (this.joinInfo.type === JoinInfo_1.JoinType.Meetup &&
            ((_c = this.joinInfo.meetingInfo) === null || _c === void 0 ? void 0 : _c.tenantId) &&
            this.featureFlags.enableAddingTenantIdInQsp) {
            url = UrlHelper_1.UrlHelper.addQueryParameter(url, "tenantId", this.joinInfo.meetingInfo.tenantId, false);
        }
        let conversationUrl = this.getUrlWithLaunchTypeInfo(this.joinInfo.conversationUrl, LaunchType_1.LaunchType.WebApp);
        // append conversationUrl is it's not related to broadcast convene url
        if (conversationUrl && !shouldUseConveneUrl) {
            // 'dogfood' is a separate route for using ring0 versions in higher rings, with an auth cookie in place
            // '/_#' should be present after dogfood route
            if (conversationUrl.includes(dogfoodRoute) &&
                conversationUrl.indexOf(exports.AUTHENTICATED_WEB_REDIRECT_ROUTE) !== dogfoodRoute.length) {
                conversationUrl =
                    conversationUrl.substr(0, dogfoodRoute.length) +
                        exports.AUTHENTICATED_WEB_REDIRECT_ROUTE +
                        conversationUrl.substr(dogfoodRoute.length);
            }
            else if (conversationUrl.indexOf(exports.AUTHENTICATED_WEB_REDIRECT_ROUTE) !== 0 &&
                !conversationUrl.includes(dogfoodRoute)) {
                conversationUrl = exports.AUTHENTICATED_WEB_REDIRECT_ROUTE + conversationUrl;
            }
            if (!this.joinInfo.isAnonymousEnabled || this.isT21WebMeetingJoinSupported()) {
                conversationUrl = conversationUrl.replace(exports.AUTHENTICATED_WEB_REDIRECT_ROUTE, "#");
            }
            url += conversationUrl;
        }
        if (this.joinInfo.redirectFromPDS || this.environment.isDgjEnvironment) {
            // QSP to prevent infinite redirect loop between JL and PDS
            url = UrlHelper_1.UrlHelper.addPDSQueryParameter(url, "preventJLRedirect", "true");
        }
        url = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, "deeplinkId", this.joinInfo.deepLinkIds.web, true);
        if (this.joinInfo.laExpId) {
            url = UrlHelper_1.UrlHelper.addQueryParameter(url, "laExpId", this.joinInfo.laExpId, false);
        }
        const isT21Meeting = this.isT21WebMeetingJoinSupported() && !shouldUseConveneUrl;
        const isMcasFlag = this.featureFlags.enableMcasWebRedirect && isT21Meeting;
        const isMcasTenantTurnedOn = this.T21EnableMcasForTenant() && isT21Meeting;
        if (isMcasFlag || isMcasTenantTurnedOn) {
            url = UrlHelper_1.UrlHelper.addQueryParameter(url, "enablemcas", "true", false);
        }
        return url;
    }
    getAutoJoinWebBehaviour() {
        // Linux app is deprecated, so force customers to join on web until PWA functionality is implemented
        // For TFL Insider program, there is no support on T1 we will be showing not supported page with instructions to join on Win11 or mobile
        if (this.environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Linux &&
            this.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            this.featureFlags.enableTflInsiderProgram &&
            this.joinInfo.type === JoinInfo_1.JoinType.TeamsInsider) {
            return {
                shouldAutoJoinWeb: false,
            };
        }
        if (this.featureFlags.badOSRedirectToWebJoin &&
            this.environment.isWebClientSupportedBrowser &&
            !this.environment.isNativeClientSupportedOS) {
            return {
                shouldAutoJoinWeb: true,
                reason: AutoJoinWebReasons.UnsupportedOS,
            };
        }
        if (this.joinInfo.redirectToLightMeetingsExperienceForDGJ) {
            return {
                shouldAutoJoinWeb: true,
                reason: AutoJoinWebReasons.LightMeetingsExperienceForDGJ,
            };
        }
        if (this.joinInfo.forceLightMeetingsExperienceOnWeb) {
            return {
                shouldAutoJoinWeb: true,
                reason: AutoJoinWebReasons.ForcedLightMeetingsExperienceForWeb,
            };
        }
        if (this.joinInfo.redirectFromPDS) {
            return {
                shouldAutoJoinWeb: true,
                reason: AutoJoinWebReasons.RedirectFromPDS,
            };
        }
        // If web client is supported, but we disable desktops buttons for TFL meet we should auto redirect
        // because we would only display continue to web button
        if (this.environment.isWebClientSupportedBrowser &&
            this.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            this.joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode &&
            this.featureFlags.disableTfLDesktop) {
            return {
                shouldAutoJoinWeb: true,
                reason: AutoJoinWebReasons.ContinueToWebAsOnlyOption,
            };
        }
        // Linux app is deprecated, so force customers to join on web until PWA functionality is implemented
        // For TFL Communities and Community Posts do not autojoin on web if not supported on T1 yet
        if (this.environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Linux &&
            this.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            this.environment.isWebClientSupportedBrowser &&
            (this.featureFlags.enableTflCommunitiesOnT1 ||
                (this.joinInfo.type !== JoinInfo_1.JoinType.TflCommunity &&
                    !(this.environment.isTflEnvironment &&
                        this.joinInfo.type === JoinInfo_1.JoinType.Message &&
                        this.featureFlags.enableTflMessagePostAsCommunity)))) {
            return {
                shouldAutoJoinWeb: true,
                reason: AutoJoinWebReasons.ContinueToWebAsOnlyOption,
            };
        }
        if (this.featureFlags.enableWebJoin &&
            this.environment.isWebClientSupportedBrowser &&
            this.joinInfo.forceWebJoin && // <- webjoin=true or enabled for special experiment
            this.environment.deviceType === Environment_1.DeviceTypes.Desktop) {
            return {
                shouldAutoJoinWeb: true,
                reason: AutoJoinWebReasons.UrlParameter,
            };
        }
        return {
            shouldAutoJoinWeb: false,
        };
    }
    redirectToWebJoin(autoJoinReason, inEdge) {
        const url = this.getOpenInTeamsWebUrl(autoJoinReason);
        let event;
        if (autoJoinReason) {
            event = new TelemetryEvents_1.AutoRedirectOpenTeamsEvent(this.joinInfo, autoJoinReason);
        }
        else {
            event = new TelemetryEvents_1.OpenTeamsEvent(this.joinInfo, inEdge ? { redirectToEdge: true } : undefined);
        }
        Logger_1.default.log(event);
        UrlHelper_1.UrlHelper.redirect((inEdge ? "microsoft-edge:" : "") + url);
    }
    redirectToDownloadPage() {
        const event = new TelemetryEvents_1.OpenDownloadPageLink();
        Logger_1.default.log(event);
        UrlHelper_1.UrlHelper.openInTab(UrlHelper_1.UrlHelper.getOrigin() + ConfigurationService_1.configurationService.getConfig().urls.paths.download);
    }
    isMsLaunchSupported() {
        if (window.navigator.msLaunchUri === undefined) {
            return false;
        }
        if (this.environment.os !== Environment_1.OperatingSystems.Win) {
            return false;
        }
        if (this.environment.browserName === Environment_1.Browsers.Edge && !this.featureFlags.msLaunch) {
            return false;
        }
        return true;
    }
    isAutoDownloadExperimentSupported() {
        return (this.featureFlags.enableAutoDownload &&
            !this.featureFlags.suppressAutoDownload &&
            !this.hasAutoDownloadAttemptKey &&
            !this.environment.isTflEnvironment &&
            (this.joinInfo.type === JoinInfo_1.JoinType.Meetup || this.joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode));
    }
    shouldRedirectToLightMeetings() {
        // Redirect is supported only in anonymous sessions
        if (!this.joinInfo.isAnonymous) {
            return false;
        }
        // Redirect is supported only for meeting links
        if (this.joinInfo.type !== JoinInfo_1.JoinType.Meetup && this.joinInfo.type !== JoinInfo_1.JoinType.MeetupWithCode) {
            return false;
        }
        // Redirect only for Firefox and Safari if respective feature flags are enabled
        return ((this.environment.browserName === Environment_1.Browsers.Firefox &&
            this.featureFlags.enableLimeRedirectForFirefox) ||
            (this.environment.browserName === Environment_1.Browsers.Safari &&
                this.featureFlags.enableLimeRedirectForSafari));
    }
    getLightMeetingsUrl() {
        var _a, _b;
        const buildVersion = this.joinInfo.redirectToLightMeetingsExperienceForDGJ
            ? this.featureFlags.lightMeetingsBuildVersion
            : this.featureFlags.lightMeetingsWebBuildVersion;
        return (0, LightMeetingsHelper_1.buildLightMeetingsUrl)(this.joinInfo.type === JoinInfo_1.JoinType.Meetup
            ? {
                conversationId: this.joinInfo.threadId || "",
                tenantId: ((_a = this.joinInfo.meetingInfo) === null || _a === void 0 ? void 0 : _a.tenantId) || "",
                organizerId: ((_b = this.joinInfo.meetingInfo) === null || _b === void 0 ? void 0 : _b.organizerId) || "",
                messageId: this.joinInfo.messageId,
            }
            : {
                meetingUrl: `${UrlHelper_1.UrlHelper.getOrigin()}${this.joinInfo.deepLinkUrl}`,
                meetingCode: this.joinInfo.code || "",
                passcode: this.joinInfo.passcode,
            }, this.featureFlags.lightMeetingsContainer, buildVersion, this.joinInfo.deepLinkIds.web);
    }
    /**
     * function that checks if the T21 Web Anon Meeting Join preflight
     * rollout feature is enabled for Join Launcher.
     * @todo: Task 3383987: [JL] clean up core tasks and rollout plan changes
     */
    isT21WebMeetingJoinSupported() {
        // For TFL routing to T21 Web is enabled for meet links, similar to what TFW has for meetup links below
        // Also note that TFL is fully on T21 Web so browser support is evaluated earlier in EnvironmentService
        if (this.environment.isTflEnvironment &&
            this.joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode &&
            this.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            !this.joinInfo.forceWebJoin) {
            return true;
        }
        return (this.featureFlags.enableT21WebRedirect &&
            this.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            !this.environment.isDgjEnvironment &&
            !this.joinInfo.forceWebJoin && // when webjoin=true presents, we should not redirect to T2
            !this.isMeetingJoinDeeplinkFromTfl() &&
            this.isBrowserSupportedForV2Redirect() &&
            this.isMeetingJoinDeeplink());
    }
    isMeetingJoinDeeplink() {
        return this.joinInfo.type === JoinInfo_1.JoinType.Meetup || this.joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode;
    }
    T21EnableMcasForTenant() {
        if (this.featureFlags.enableT21McasForTenant &&
            this.featureFlags.enableT21McasForTenant.length > 0) {
            return !!this.featureFlags.enableT21McasForTenant.find(tenantId => { var _a; return tenantId !== "" && tenantId === ((_a = this.joinInfo.meetingInfo) === null || _a === void 0 ? void 0 : _a.tenantId); });
        }
        return false;
    }
    isMeetingJoinDeeplinkFromTfl() {
        return this.environment.isTflEnvironment && this.joinInfo.isTflLink;
    }
    isBrowserSupportedForV2Redirect() {
        /**
         *  /v2 is only supported for EdgeChromium and Chrome by default
         *  Safari and Firefox based on respective FFs
         */
        if (!this.isBrowserVersionSupportedForV2Redirect()) {
            return false;
        }
        switch (this.environment.browserName) {
            case Environment_1.Browsers.Chrome:
            case Environment_1.Browsers.EdgeChromium:
                return true;
            case Environment_1.Browsers.Safari:
                return this.featureFlags.enableT21WebRedirectSafari;
            case Environment_1.Browsers.Firefox:
                return this.featureFlags.enableT21WebRedirectFirefox;
            default:
                return false;
        }
    }
    isBrowserVersionSupportedForV2Redirect() {
        var _a, _b, _c;
        const browserVersion = this.environment.browserMajorVersion;
        let requiredVersion = 0;
        switch (this.environment.browserName) {
            case Environment_1.Browsers.Chrome:
            case Environment_1.Browsers.EdgeChromium:
                requiredVersion = (_a = Utils_1.Utils.parseNumber(this.featureFlags.minT21SupportedChromiumVersion)) !== null && _a !== void 0 ? _a : 0;
                break;
            case Environment_1.Browsers.Safari:
                requiredVersion = (_b = Utils_1.Utils.parseNumber(this.featureFlags.minT21SupportedSafariVersion)) !== null && _b !== void 0 ? _b : 0;
                break;
            case Environment_1.Browsers.Firefox:
                requiredVersion = (_c = Utils_1.Utils.parseNumber(this.featureFlags.minT21SupportedFirefoxVersion)) !== null && _c !== void 0 ? _c : 0;
                break;
            default:
                return false;
        }
        return browserVersion >= requiredVersion;
    }
    getUrlWithLaunchTypeInfo(url, launchType) {
        if (!this.joinInfo.isTflLink) {
            return url;
        }
        // Old logic - encoding launchType into 'v' query param - keeping for backwards compatibility as it is reported from client side
        const expVersionQueryParamName = "v";
        const expValue = UrlHelper_1.UrlHelper.getQueryParameter(expVersionQueryParamName, url) || "";
        const lastDotIndex = expValue.lastIndexOf(".");
        let experimentId = expValue;
        if (lastDotIndex >= 0) {
            experimentId = expValue.substr(0, lastDotIndex);
        }
        const newExpValue = `${experimentId}.${tflLaunchTypesExp[launchType]}`;
        let uri = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, expVersionQueryParamName, newExpValue, false);
        // New logic - passing launchType in separate param - need to be tracked by clients before old logic can be removed
        uri = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(uri, "launchType", exports.tflLaunchTypes[launchType], false);
        uri = this.getEnrichedUrlForTfl(uri);
        // When new launch type to view details is used propagate it as separate action that can be evaluated by client
        if (launchType === LaunchType_1.LaunchType.ViewDetails) {
            uri = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(uri, "action", "view", false);
        }
        else if (CommunityEventsHelper_1.CommunityEventsHelper.isCommunityEvent(this.joinInfo)) {
            // In all other cases, for community events only action should be open
            uri = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(uri, "action", "open", false);
        }
        return uri;
    }
    getEnrichedUrlForTfl(url) {
        const context = ServerParamsService_1.default.getServerParams().requestContext;
        // Adding/updating eventId and communityId per context
        if (context.exchangeId) {
            url = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, "eventId", context.exchangeId, true);
        }
        if (context.communityThreadId) {
            url = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, "communityId", context.communityThreadId, true);
        }
        return url;
    }
}
exports.LauncherService = LauncherService;
