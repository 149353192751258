"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneDS = void 0;
const _1ds_analytics_web_js_1 = require("@microsoft/1ds-analytics-web-js");
const ConfigurationService_1 = require("./ConfigurationService");
class OneDS {
    static init() {
        try {
            this.analytics = new _1ds_analytics_web_js_1.ApplicationInsights();
            const options = {
                instrumentationKey: ConfigurationService_1.configurationService.getConfig().telemetry.tenantToken,
                endpointUrl: ConfigurationService_1.configurationService.getConfig().OneDsCollectorUri,
                webAnalyticsConfiguration: {
                    autoCapture: {
                        scroll: true,
                        resize: true,
                        jsError: true,
                        pageView: false,
                        msTags: false,
                    },
                    coreData: {
                        referrerUri: "",
                        requestUri: "",
                    },
                },
                channelConfiguration: {
                    disableTelemetry: true,
                },
            };
            this.analytics.initialize(options, []);
        }
        catch (error) {
            scriptErrorLogs.push(error);
        }
    }
    static getDeviceID() {
        return this.getCookie("MicrosoftApplicationsTelemetryDeviceId");
    }
    static getCookie(key) {
        var _a;
        const cookieManager = (_a = this.analytics) === null || _a === void 0 ? void 0 : _a.getCookieMgr();
        if (!cookieManager) {
            return "";
        }
        return cookieManager.get(key);
    }
}
exports.OneDS = OneDS;
OneDS.analytics = null;
