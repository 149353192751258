"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Environment_1 = require("../models/Environment");
/* istanbul ignore file */
const baseConfig = {
    env: Environment_1.BuildEnvironment.Prod,
    ariaCollectorUri: "https://browser.pipe.aria.microsoft.com/Collector/3.0/",
    OneDsCollectorUri: "https://browser.events.data.microsoft.com/OneCollector/1.0/",
    publicPath: "https://statics.teams.microsoft.com",
    telemetry: {
        tenantToken: "bc3902d8132f43e3ae086a009979fa88-632130b2-9455-44a6-80e2-d7457b6eb8ca-7193",
    },
    ecs: {
        host: "https://config.teams.microsoft.com",
        clientName: "Skype",
        clientVersion: "1415_0.0.0.0",
        projectTeam: "TeamsJoinLauncher",
        timeout: 1000,
    },
    urls: {
        absoluteUrls: {
            downloadHelp: "https://aka.ms/meetinghelp",
            privacyStatement: "https://go.microsoft.com/fwlink/?LinkId=521839",
            consumerHealthPrivacy: "https://go.microsoft.com/fwlink/?linkid=2259814",
            learnMore: "https://go.microsoft.com/fwlink/?linkid=2085124",
            tflJoinIssuesHelpLink: "https://go.microsoft.com/fwlink/?linkid=2194331",
            tflCommunitiesLearnMoreLink: "https://go.microsoft.com/fwlink/?linkid=2211930",
            tflInsidersLearnMoreLink: "https://go.microsoft.com/fwlink/?linkid=2199133",
            brandingService: "https://teams.microsoft.com/api/mt/beta/meetings/branding",
            brandingWithMeetingidService: "https://teams.microsoft.com/api/mt/beta/meetings/brandinginfo",
            schoolConnectionLearnMoreLink: "https://parent.microsoft.com/",
            privacyStatementService: "https://teams.microsoft.com/api/mt/beta/tenant/anonPrivacyProfile",
        },
        paths: {
            download: "/downloads",
            error: errContext => {
                if (!errContext) {
                    return "/dl/launcher/500.html";
                }
                return `/dl/launcher/500.html?errContext=${errContext}`;
            },
        },
        broadcast: {
            convenePath: "/convene/meetings",
        },
        lightMeetings: {
            thirdPartyDeviceGUID: "6c696d65",
        },
    },
    apis: {
        desktopInstaller: "/downloads/desktopurl",
        desktopContextualInstaller: "/downloads/desktopcontextualinstaller",
    },
    appStores: {
        apple: {
            iconPath: (language, publicPath) => `${publicPath}/evergreen-assets/mobilebadgesforjoinlauncher/${language}/apple.svg`,
            appLink: deepLink => `https://app.adjust.com/z6rsasy?deep_link=${encodeURIComponent(deepLink)}`,
            appID: 1113153706,
        },
        appleDirect: {
            iconPath: (language, publicPath) => `${publicPath}/evergreen-assets/mobilebadgesforjoinlauncher/${language}/apple.svg`,
            appLink: deepLink => `https://aka.ms/iosteams?referrer=${encodeURIComponent(deepLink)}`,
        },
        google: {
            iconPath: (language, publicPath) => `${publicPath}/evergreen-assets/mobilebadgesforjoinlauncher/${language}/google.png`,
            appLink: deepLink => `https://aka.ms/androidteams?referrer=${encodeURIComponent(deepLink)}`,
        },
    },
    nativeClientUnsupportedOS: [Environment_1.OperatingSystems.ChromeOS, Environment_1.OperatingSystems.Unknown],
    // although Safari is supported from version 13.1, it is set here as majority of users are already on this or newer version
    webClientSupportedBrowsers: [
        Environment_1.Browsers.Chrome,
        Environment_1.Browsers.Edge,
        Environment_1.Browsers.Safari,
        Environment_1.Browsers.EdgeChromium,
    ],
    dgjSupportedUserAgents: ["RoomOS", "ZoomRoom", "BJN-VZ"],
};
exports.default = baseConfig;
