"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeaturesService = void 0;
const ObjectHelper_1 = require("../helpers/ObjectHelper");
const UrlHelper_1 = require("../helpers/UrlHelper");
const Utils_1 = require("../helpers/Utils");
const FeatureFlags_1 = require("../models/FeatureFlags");
const lodash_1 = require("lodash");
const ServerParamsService_1 = require("./ServerParamsService");
class FeaturesService {
    getFeatureFlags() {
        if (!this.featureFlags) {
            this.featureFlags = this.computeFeatureFlags();
        }
        return this.featureFlags;
    }
    setConfigIDs(configIDs) {
        this.configIDs = configIDs;
    }
    updateFlagsWithEcs(rawEcsFlags) {
        const flags = this.getFeatureFlags();
        const ecsFlags = this.mapRawFlags(rawEcsFlags);
        this.featureFlags = ObjectHelper_1.default.mergeObjects({}, flags, ecsFlags);
        return this.featureFlags;
    }
    computeFeatureFlags() {
        const queryParams = UrlHelper_1.UrlHelper.getQueryParameters();
        const qspFlags = this.mapRawFlags(queryParams);
        const globalFlagsRaw = ServerParamsService_1.default.getServerParams().featureFlags;
        const globalFlags = this.mapRawFlags(globalFlagsRaw);
        this.modifyGlobalFlagDisableTflDesktop(globalFlags);
        return ObjectHelper_1.default.mergeObjects({}, FeatureFlags_1.defaultFeatureFlags, globalFlags, qspFlags);
    }
    // this method will save FF if is true or string with value true
    mapRawFlags(rawFlags) {
        const flags = {};
        const featureFlagNames = Object.keys(FeatureFlags_1.defaultFeatureFlags);
        featureFlagNames.forEach(flag => {
            const flagAlias = FeatureFlags_1.featureFlagAliases[flag];
            if (typeof rawFlags === "object") {
                if (rawFlags[flag] !== undefined) {
                    flags[flag] = this.parseFFValue(rawFlags[flag]);
                }
                else if (flagAlias && rawFlags[flagAlias] !== undefined) {
                    flags[flag] = this.parseFFValue(rawFlags[flagAlias]);
                }
            }
        });
        return flags;
    }
    parseFFValue(val) {
        if ((0, lodash_1.isArray)(val)) {
            return val;
        }
        const isTruthy = Utils_1.Utils.isTruthy(val);
        const numericValue = Utils_1.Utils.parseNumber(val);
        const stringValue = (0, lodash_1.isString)(val) && val !== "false" ? val : false;
        return isTruthy || numericValue || stringValue || false;
    }
    // Modification of disableTflDesktop is temporary till Desktop releases, remove when disableTfLDesktop=false in all envs.
    modifyGlobalFlagDisableTflDesktop(flags) {
        if (flags.disableTfLDesktop) {
            try {
                const settings = window.localStorage.getItem("ts.TS_ADDITIONAL_SETTINGS") || "";
                const ring = JSON.parse(settings).ring.id;
                if (["ring0", "ring0_s", "ring1", "ring2", "ring3_9"].indexOf(ring) > -1) {
                    flags.disableTfLDesktop = false;
                }
            }
            catch (e) {
                // we tried our best
            }
        }
    }
}
exports.FeaturesService = FeaturesService;
const featuresService = new FeaturesService();
exports.default = featuresService;
