"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadInProgress = void 0;
const React = require("react");
const FadeIn_1 = require("./FadeIn");
const Translate_1 = require("./Translate");
class DownloadInProgress extends React.PureComponent {
    render() {
        return (React.createElement(FadeIn_1.FadeIn, null,
            React.createElement("h1", { id: "join-prompt-header", className: "title" },
                React.createElement(Translate_1.Translate, { i18n: "hdr_during_download" })),
            React.createElement("p", { className: "desktopMessage" },
                React.createElement(Translate_1.Translate, { i18n: "txt_during_download" })),
            React.createElement("p", { className: "desktopMessage" },
                React.createElement("span", null,
                    React.createElement(Translate_1.Translate, { i18n: "txt_during_download_cont" })),
                "\u00A0",
                React.createElement("a", { className: "launchLink", id: "downloadDesktopClient2", href: "#", onClick: this.props.onDownloadClick },
                    React.createElement(Translate_1.Translate, { i18n: "txt_during_download_cont_lnk" })))));
    }
}
exports.DownloadInProgress = DownloadInProgress;
