"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConfig_1 = require("../BaseConfig");
const Environment_1 = require("../../models/Environment");
const developmentConfig = {
    env: Environment_1.BuildEnvironment.Dev,
    ecs: Object.assign(Object.assign({}, BaseConfig_1.default.ecs), { host: "" }),
    urls: Object.assign(Object.assign({}, BaseConfig_1.default.urls), { absoluteUrls: Object.assign(Object.assign({}, BaseConfig_1.default.urls.absoluteUrls), { brandingService: "/api/mt/beta/meetings/branding", brandingWithMeetingidService: "/api/mt/beta/meetings/brandinginfo" }) }),
};
exports.default = developmentConfig;
