"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootView = exports.ModernViewType = void 0;
const React = require("react");
const CoreContext_1 = require("../../contexts/CoreContext");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const DownloadState_1 = require("../../models/DownloadState");
const Environment_1 = require("../../models/Environment");
const JoinInfo_1 = require("../../models/JoinInfo");
const LaunchType_1 = require("../../models/LaunchType");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const DownloadService_1 = require("../../services/DownloadService");
const Logger_1 = require("../../services/Logger");
const BusyAnimation_1 = require("../common/modern/BusyAnimation");
const View_1 = require("../common/View");
const AutoDownloader_1 = require("./AutoDownloader");
const JoinView_1 = require("./JoinView");
const Launcher_1 = require("./Launcher");
const MobileContainer_1 = require("./MobileContainer");
const ModernViewContainer_1 = require("./ModernViewContainer");
const TflDesktopCommunityView_1 = require("./TflDesktopCommunityView");
const UnsupportedView_1 = require("../common/modern/UnsupportedView");
const TflDesktopGoogleSyncView_1 = require("./TflDesktopGoogleSyncView");
const StateHelper_1 = require("../../helpers/StateHelper");
var ModernViewType;
(function (ModernViewType) {
    ModernViewType[ModernViewType["None"] = 0] = "None";
    ModernViewType[ModernViewType["Meeting"] = 1] = "Meeting";
    ModernViewType[ModernViewType["FreemiumOnboarding"] = 2] = "FreemiumOnboarding";
    ModernViewType[ModernViewType["MobileMeeting"] = 3] = "MobileMeeting";
    ModernViewType[ModernViewType["ShareEmail"] = 4] = "ShareEmail";
    ModernViewType[ModernViewType["TflModernView"] = 5] = "TflModernView";
})(ModernViewType = exports.ModernViewType || (exports.ModernViewType = {}));
class RootView extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (props.joinInfo !== state.joinContext.joinInfo) {
            return { joinContext: { joinInfo: props.joinInfo } };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.onLaunchClick = () => {
            this.setState(oldState => ({
                launcherKey: oldState.launcherKey + 1,
                launchType: LaunchType_1.LaunchType.OpenApp,
            }));
            const event = new TelemetryEvents_1.TryOpenAgainEvent(this.state.joinContext.joinInfo);
            Logger_1.default.log(event);
        };
        this.updateDownloadStep = (newStep) => {
            this.setState(oldState => {
                const { joinContext } = oldState;
                joinContext.downloadState.step = newStep;
                return { joinContext };
            });
        };
        let downloadKey = 0;
        if (this.props.joinInfo.type === JoinInfo_1.JoinType.FremiumMeeting) {
            downloadKey = this.props.featureFlags.suppressAutoDownload ? 0 : 1;
        }
        this.state = {
            downloadKey,
            launcherKey: this.isPromptSuppressed(),
            joinContext: {
                joinInfo: this.props.joinInfo,
                downloadState: {
                    step: DownloadState_1.DownloadStep.Init,
                    update: this.updateDownloadStep,
                },
            },
            launchType: LaunchType_1.LaunchType.Automatic,
        };
    }
    renderView(joinInfo, environment, featureFlags, isModernView, modernViewType, launchUrl, isMobileModernView) {
        if (environment.os === Environment_1.OperatingSystems.WinPhone) {
            return React.createElement(UnsupportedView_1.UnsupportedView, null);
        }
        switch (environment.deviceType) {
            case Environment_1.DeviceTypes.Mobile:
            case Environment_1.DeviceTypes.Tablet:
                if (featureFlags.enableMobilePage) {
                    return (React.createElement(MobileContainer_1.MobileContainer, { onLaunchClick: this.onLaunchClick, launchResult: this.state.launchResult, launchUrl: launchUrl, isTflLink: joinInfo.isTflLink, isMobileModernView: isMobileModernView, joinInfo: joinInfo }));
                }
            // break omitted
            case Environment_1.DeviceTypes.Desktop:
            default:
                if ((featureFlags.enableWebJoin &&
                    joinInfo.forceWebJoin &&
                    environment.isWebClientSupportedBrowser) ||
                    joinInfo.redirectToLightMeetingsExperienceForDGJ) {
                    const panelViewEvent = new TelemetryEvents_1.PanelViewEvent("join_launcher_loading", joinInfo);
                    Logger_1.default.logUserBiPanelView(panelViewEvent);
                    return (React.createElement(View_1.View, null,
                        React.createElement("div", { className: "centered fillSpace" },
                            React.createElement(BusyAnimation_1.BusyAnimation, { size: "3" }))));
                }
                if (isModernView) {
                    return (React.createElement(ModernViewContainer_1.ModernViewContainer, { onLaunchClick: this.onLaunchClick, launchResult: this.state.launchResult, type: modernViewType, computedFlags: this.props.computedFlags }));
                }
                if (joinInfo.type === JoinInfo_1.JoinType.TflCommunity ||
                    (joinInfo.type === JoinInfo_1.JoinType.Message && environment.isTflEnvironment)) {
                    return (React.createElement(TflDesktopCommunityView_1.TflDesktopCommunityView, { onLaunchClick: this.onLaunchClick, launchResult: this.state.launchResult, joinInfo: joinInfo }));
                }
                if (featureFlags.enableTflDesktopGoogleSync &&
                    environment.isWindows11 &&
                    StateHelper_1.StateHelper.isGoogleAuth(joinInfo)) {
                    return (React.createElement(TflDesktopGoogleSyncView_1.TflDesktopGoogleSyncView, { onLaunchClick: this.onLaunchClick, launchResult: this.state.launchResult, joinInfo: joinInfo }));
                }
                return (React.createElement(JoinView_1.JoinView, { onLaunchClick: this.onLaunchClick, launchResult: this.state.launchResult, joinInfo: joinInfo }));
        }
    }
    render() {
        if (!this.state.joinContext.joinInfo.isValid) {
            return null;
        }
        return (React.createElement(JoinLauncherContext_1.JoinLauncherContextProvider, { value: this.state.joinContext },
            React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment, featureFlags, isModernView, modernViewType, launcherService, isMobileModernView, }) => (React.createElement("div", { id: this.getRootId(isModernView, modernViewType) },
                React.createElement(Launcher_1.Launcher, { key: this.state.launcherKey, trigger: !!this.state.launcherKey, launchType: this.state.launchType, featureFlags: featureFlags, environment: environment, joinInfo: this.state.joinContext.joinInfo, launcherService: launcherService, launchResultCallback: launchResult => this.launchResultCallback(launchResult), isMobileModernView: isMobileModernView }),
                React.createElement(AutoDownloader_1.AutoDownloader, { trigger: !!this.state.downloadKey || launcherService.isAutoDownloadExperimentSupported(), featureFlags: featureFlags, downloadBehavior: DownloadService_1.default.getDownloadBehaviour(this.state.joinContext.joinInfo, environment, featureFlags, launcherService, this.state.joinContext.downloadState), autoDownloadCallback: () => this.autoDownloadCallback() }),
                this.renderView(this.state.joinContext.joinInfo, environment, featureFlags, isModernView, modernViewType, launcherService.getClientLaunchUrl(LaunchType_1.LaunchType.OpenApp), isMobileModernView))))));
    }
    launchResultCallback(launchResult) {
        this.setState({ launchResult });
    }
    autoDownloadCallback() {
        this.setState(oldState => {
            const { joinContext } = oldState;
            joinContext.joinInfo.isAutoDownloaderTriggered = true;
            return { downloadKey: oldState.downloadKey + 1 };
        });
    }
    isPromptSuppressed() {
        // Supress prompt for Teams Insider (if enabled) and on non Win11 (if not enabled there)
        if (this.props.featureFlags.enableTflInsiderProgram &&
            !this.props.featureFlags.enableTflInsiderProgramNonWin11 &&
            this.props.joinInfo.type === JoinInfo_1.JoinType.TeamsInsider &&
            this.props.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            !this.props.environment.isWindows11) {
            return 0;
        }
        // Suppress prompt for School Connection (if enabled) for Desktop devices
        if (this.props.featureFlags.enableSchoolConnection &&
            this.props.joinInfo.type === JoinInfo_1.JoinType.SchoolConnection &&
            this.props.environment.deviceType === Environment_1.DeviceTypes.Desktop) {
            return 0;
        }
        // All community/community post suppressing was based on not having T1 join support
        // If this support is in place/enabled we don't need to suppress anymore as the default QR code experience pointing users to mobile platforms won't be used
        // Once the FF is rolled out all the conditionals below will be cleandup
        if (!this.props.featureFlags.enableTflCommunitiesOnT1) {
            // suppress on TFL Communities if they are not enabled
            if (this.props.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
                this.props.joinInfo.type === JoinInfo_1.JoinType.TflCommunity &&
                !this.props.environment.isWindows11) {
                return 0;
            }
            // suppress on TFL Community Posts on default community experience (served on non Win11)
            if (this.props.environment.isTflEnvironment &&
                this.props.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
                this.props.joinInfo.type === JoinInfo_1.JoinType.Message &&
                this.props.featureFlags.enableTflMessagePostAsCommunity &&
                !this.props.environment.isWindows11) {
                return 0;
            }
        }
        // supress on TfL meeting link on Desktop
        if (this.props.environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            this.props.joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode &&
            this.props.featureFlags.disableTfLDesktop) {
            return 0;
        }
        return this.props.featureFlags.suppressPrompt ? 0 : 1;
    }
    getRootId(isModernView, modernViewType) {
        if (isModernView) {
            return modernViewType === ModernViewType.MobileMeeting ? "mobileRootViewV2" : "rootViewV2";
        }
        return "rootView";
    }
}
exports.RootView = RootView;
