"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Utils_1 = require("../helpers/Utils");
class LogsCollectorService {
    constructor() {
        this.download = () => {
            try {
                Utils_1.Utils.downloadLogsCollectorFile(JSON.stringify(this.logs, null, "\t"));
            }
            catch (e) {
                Utils_1.Utils.downloadLogsCollectorFile(`${e.message}\n${e.error}`);
            }
        };
        this.logServerParams = (serverParams) => {
            this.logs.serverParams = serverParams;
        };
        this.logEcsResponse = (ecsResponse) => {
            this.logs.ecsResponse = ecsResponse;
        };
        this.logBootstrapFields = (environment, userContext, joinInfo, featureFlags) => {
            this.logs.environment = environment;
            this.logs.userContext = userContext;
            this.logs.joinInfo = joinInfo;
            this.logs.computedFeatureFlags = featureFlags;
        };
        this.logs = {};
    }
}
const logsCollectorService = new LogsCollectorService();
exports.default = logsCollectorService;
