"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Launching = void 0;
const React = require("react");
const BusyAnimation_1 = require("./BusyAnimation");
const Translate_1 = require("../Translate");
require("./Launching.scss");
class Launching extends React.PureComponent {
    render() {
        const size = this.props.size || 2;
        return (React.createElement("div", { className: "content", id: "launching" },
            React.createElement(BusyAnimation_1.BusyAnimation, { size: size.toString() }),
            React.createElement("h1", null,
                React.createElement(Translate_1.Translate, { i18n: this.props.text }))));
    }
}
exports.Launching = Launching;
