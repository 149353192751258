"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConfig_1 = require("../BaseConfig");
const Environment_1 = require("../../models/Environment");
const dodConfig = {
    env: Environment_1.BuildEnvironment.Dod,
    ariaCollectorUri: "https://pf.pipe.aria.microsoft.com/Collector/3.0",
    OneDsCollectorUri: "https://pf.events.data.microsoft.com/OneCollector/1.0/",
    publicPath: "https://statics.dod.teams.microsoft.us",
    telemetry: {
        tenantToken: "ff9c659dc93c4d18bc8b9ba70781b398-a1e71048-fec7-4aa4-8da3-6b0e0a4fbcea-7190",
    },
    urls: Object.assign(Object.assign({}, BaseConfig_1.default.urls), { absoluteUrls: Object.assign(Object.assign({}, BaseConfig_1.default.urls.absoluteUrls), { brandingService: "https://middletier.dod.teams.microsoft.us/beta/meetings/branding", brandingWithMeetingidService: "https://middletier.dod.teams.microsoft.us/beta/meetings/brandinginfo" }) }),
    ecs: Object.assign(Object.assign({}, BaseConfig_1.default.ecs), { host: "https://config.ecs.dod.teams.microsoft.us" }),
};
exports.default = dodConfig;
