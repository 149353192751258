"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TflDesktopGoogleSyncView = void 0;
const React = require("react");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const TeamsLogo_1 = require("../common/TeamsLogo");
const Translate_1 = require("../common/Translate");
const View_1 = require("../common/View");
require("./TflDesktopGoogleSyncView.scss");
const googleSyncImg = require("../../images/google_sync.svg");
const Button_1 = require("../common/Button");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const Logger_1 = require("../../services/Logger");
const StateHelper_1 = require("../../helpers/StateHelper");
class TflDesktopGoogleSyncView extends React.Component {
    constructor(props) {
        super(props);
        this.finalPanelViewReported = false;
    }
    render() {
        return (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            const googleSyncType = StateHelper_1.StateHelper.getStateValue(joinInfo);
            this.reportSuccessPanelView("google_sync_loaded", joinInfo, {
                "DataBag.GoogleSyncType": googleSyncType,
            });
            return (React.createElement(View_1.View, { className: "joinView" },
                React.createElement("div", { className: "header", role: "banner" },
                    React.createElement(TeamsLogo_1.TeamsLogo, null)),
                React.createElement("div", { className: "bodyColumnCentered", role: "main" },
                    React.createElement(React.Fragment, null,
                        React.createElement("img", { className: "partyImage", id: "party-image", src: googleSyncImg, "aria-hidden": "true" }),
                        this.renderBody(joinInfo, googleSyncType)))));
        }));
    }
    renderBody(joinInfo, googleSyncType) {
        switch (googleSyncType) {
            case "Calendar":
                return (React.createElement("div", { className: "details" },
                    React.createElement("div", { className: "googleTitle" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_google_sync_title" }),
                        React.createElement("br", null)),
                    React.createElement("div", { className: "description", id: "googleCalendar" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_google_calendar_sync_description" }),
                        React.createElement("br", null)),
                    React.createElement(Button_1.Button, { className: "primary", id: "openTeamsButton", onClick: () => {
                            this.props.onLaunchClick();
                            this.reportPanelAction(TelemetryEvents_1.ModuleNames.ShowGoogleSyncType, joinInfo, {
                                "DataBag.GoogleSyncType": googleSyncType,
                            });
                        } },
                        React.createElement(Translate_1.Translate, { i18n: "Open Teams" }))));
            case "Contacts":
                return (React.createElement("div", { className: "details" },
                    React.createElement("div", { className: "googleTitle" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_google_sync_title" }),
                        React.createElement("br", null)),
                    React.createElement("div", { className: "description", id: "googleContacts" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_google_contacts_sync_description" }),
                        React.createElement("br", null)),
                    React.createElement(Button_1.Button, { className: "primary", id: "openTeamsButton", onClick: () => {
                            this.props.onLaunchClick();
                            this.reportPanelAction(TelemetryEvents_1.ModuleNames.ShowGoogleSyncType, joinInfo, {
                                "DataBag.GoogleSyncType": googleSyncType,
                            });
                        } },
                        React.createElement(Translate_1.Translate, { i18n: "Open Teams" }))));
            default:
                return (React.createElement("div", { className: "details" },
                    React.createElement("div", { className: "googleTitle" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_google_sync_title" }),
                        React.createElement("br", null)),
                    React.createElement("div", { className: "description" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_google_default_description" }),
                        React.createElement("br", null)),
                    React.createElement(Button_1.Button, { className: "primary", id: "tryProtocolLaunchTeamsClient", onClick: () => {
                            this.props.onLaunchClick();
                            this.reportPanelAction(TelemetryEvents_1.ModuleNames.ShowGoogleSyncType, joinInfo, {
                                "DataBag.GoogleSyncType": googleSyncType,
                            });
                        } },
                        React.createElement(Translate_1.Translate, { i18n: "Open Teams" }))));
        }
    }
    reportSuccessPanelView(name, joinInfo, props) {
        if (!this.finalPanelViewReported) {
            const panelViewEvent = new TelemetryEvents_1.PanelViewEvent(name, joinInfo, Object.assign(Object.assign({}, props), { launchResult: this.props.launchResult }));
            Logger_1.default.logUserBiPanelView(panelViewEvent);
            this.finalPanelViewReported = true;
        }
    }
    reportPanelAction(moduleName, joinInfo, props) {
        const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.GoogleSyncJoinView, moduleName, joinInfo, Object.assign({}, props));
        Logger_1.default.logUserBiPanelAction(panelActionEvent);
    }
}
exports.TflDesktopGoogleSyncView = TflDesktopGoogleSyncView;
