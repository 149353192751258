"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaunchType = void 0;
var LaunchType;
(function (LaunchType) {
    LaunchType[LaunchType["Automatic"] = 0] = "Automatic";
    LaunchType[LaunchType["OpenApp"] = 1] = "OpenApp";
    LaunchType[LaunchType["GoToStore"] = 2] = "GoToStore";
    LaunchType[LaunchType["WebApp"] = 3] = "WebApp";
    LaunchType[LaunchType["ViewDetails"] = 4] = "ViewDetails";
})(LaunchType = exports.LaunchType || (exports.LaunchType = {}));
