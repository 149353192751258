"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlHelper = exports.AUTHENTICATED_WEB_REDIRECT_ROUTE = void 0;
const JoinInfo_1 = require("../models/JoinInfo");
exports.AUTHENTICATED_WEB_REDIRECT_ROUTE = "/_#/";
class UrlHelper {
    static getQueryParameters(url) {
        const query = UrlHelper.getQueryString(url);
        const defaultEmpty = {};
        if (query.length < 1) {
            return defaultEmpty;
        }
        return query
            .substr(1)
            .split("&")
            .map(value => {
            const split = value.split("=");
            const mappedValue = {
                key: decodeURIComponent(split[0]),
                value: decodeURIComponent(split[1] || ""),
            };
            return mappedValue;
        })
            .reduce((prev, current) => {
            if (prev[current.key]) {
                const prevValue = prev[current.key];
                if (typeof prevValue === "string") {
                    prev[current.key] = [prev[current.key]];
                }
                prev[current.key].push(current.value);
            }
            else {
                prev[current.key] = current.value;
            }
            return prev;
        }, defaultEmpty);
    }
    static getHash() {
        return location.hash || "";
    }
    static getHashParameters(supportedJoinTypes = [JoinInfo_1.JoinType.Meetup]) {
        var _a;
        const hash = location.hash;
        if (!hash) {
            return {
                url: "",
                type: "",
            };
        }
        // We need to translate the hash which always starts with #
        // to url which should start with AUTHENTICATED_WEB_REDIRECT_ROUTE
        // Format: /_#/l/<type>/<conversationUrl>
        // or: /_#/<type>/<conversationUrl>
        const url = hash.replace(/^#\/{0,1}/, exports.AUTHENTICATED_WEB_REDIRECT_ROUTE);
        const typeValue = ((_a = url.match(/\/_#\/l?\/?([^\/]*)/)) === null || _a === void 0 ? void 0 : _a[1]) || "";
        // We only support valid meeting join types in our hash
        if (!supportedJoinTypes.includes(typeValue)) {
            return {
                url: "",
                type: "",
            };
        }
        return {
            url,
            type: typeValue,
        };
    }
    static getQueryParameter(key, url) {
        return UrlHelper.getQueryParameters(url)[key];
    }
    static removeQueryParameter(url, key) {
        const urlparts = url.split("?");
        if (urlparts.length >= 2) {
            const prefix = `${encodeURIComponent(key)}=`;
            const params = urlparts[1].split(/[&;]/g);
            for (let i = params.length; i-- > 0;) {
                if (params[i].lastIndexOf(prefix, 0) !== -1) {
                    params.splice(i, 1);
                }
            }
            url = urlparts[0] + (params.length > 0 ? `?${params.join("&")}` : "");
        }
        return url;
    }
    static addPDSQueryParameter(url, key, value) {
        try {
            const urlBuilder = new URL(url);
            urlBuilder.searchParams.append(key, value);
            return urlBuilder.toString();
        }
        catch (_a) {
            return url;
        }
    }
    static addQueryParameter(url, key, value, encodeValue) {
        if (encodeValue) {
            value = encodeURIComponent(value);
        }
        const queryPair = key ? `${key}=${value}` : value;
        // if url does not have ?, add ?=key=val
        if (url.indexOf("?") === -1) {
            return url.concat("?", queryPair);
        }
        // if url has key=val pair add key=val&key1=val1
        if (url.indexOf("=") !== -1) {
            return url.concat("&", queryPair);
        }
        // if url is only ? , return ?key=value
        return url.substr(1).length === 0 ? url.concat(queryPair) : url.concat("&", queryPair);
    }
    static replaceQueryParameter(url, key, newValue, encodeValue = true) {
        if (UrlHelper.getQueryParameter(key, url)) {
            const urlWithParamRemoved = UrlHelper.removeQueryParameter(url, key);
            return UrlHelper.addQueryParameter(urlWithParamRemoved, key, newValue, encodeValue);
        }
        return url;
    }
    static addOrReplaceQueryParameter(url, key, newValue, encodeValue = true) {
        if (UrlHelper.getQueryParameter(key, url)) {
            return UrlHelper.replaceQueryParameter(url, key, newValue, encodeValue);
        }
        return UrlHelper.addQueryParameter(url, key, newValue, encodeValue);
    }
    static stripParams(url) {
        const queryStartIndex = url.indexOf("?");
        if (queryStartIndex === -1) {
            return url;
        }
        return url.substr(0, queryStartIndex);
    }
    static redirect(url) {
        if (window.Cypress) {
            UrlHelper.redirs.push(url);
        }
        else {
            location.assign(url);
        }
    }
    static openInTab(url) {
        const win = window.open(url, "_blank");
        if (win) {
            win.focus();
        }
    }
    static getQueryString(url) {
        if (!url) {
            return location.search;
        }
        const startIndex = url.indexOf("?");
        if (startIndex === -1) {
            return "";
        }
        return url.substr(startIndex);
    }
    static getOrigin(url = window.location.origin) {
        // For browsers that don't support window.location.origin
        if (!url) {
            return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;
        }
        return url;
    }
    static downloadFile(url) {
        const frame = window.document.createElement("iframe");
        frame.style.display = "none";
        frame.src = url;
        window.document.body.appendChild(frame);
    }
    static pathParts(url) {
        return (url
            // remove domain
            .replace(/^.*\/\/[^/]+/, "")
            // remove query string
            .split("?")[0]
            .split("/")
            .filter(p => !!p));
    }
    static get redirects() {
        if (window.Cypress) {
            return UrlHelper.redirs;
        }
        return [];
    }
}
exports.UrlHelper = UrlHelper;
UrlHelper.redirs = [];
if (window.Cypress) {
    window.UrlHelper = UrlHelper;
}
