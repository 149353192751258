"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildEnvironment = exports.DeviceTypes = exports.Browsers = exports.CanonicalOperatingSystems = exports.OperatingSystems = exports.ArchitectureTypes = exports.TeamsRing = void 0;
var TeamsRing;
(function (TeamsRing) {
    TeamsRing["Ring0_s"] = "ring0_s";
    TeamsRing["Ring0"] = "ring0";
    TeamsRing["Ring1"] = "ring1";
    TeamsRing["Ring1_5"] = "ring1_5";
    TeamsRing["Ring1_6"] = "ring1_6";
    TeamsRing["Ring2"] = "ring2";
    TeamsRing["Ring3"] = "ring3";
    TeamsRing["Ring3_6"] = "ring3_6";
    TeamsRing["Ring3_9"] = "ring3_9";
    TeamsRing["General"] = "general";
    TeamsRing["General_gcc"] = "general_gcc";
})(TeamsRing = exports.TeamsRing || (exports.TeamsRing = {}));
var ArchitectureTypes;
(function (ArchitectureTypes) {
    ArchitectureTypes["x64"] = "x64";
    ArchitectureTypes["Other"] = "";
})(ArchitectureTypes = exports.ArchitectureTypes || (exports.ArchitectureTypes = {}));
var OperatingSystems;
(function (OperatingSystems) {
    OperatingSystems["WinPhone"] = "Windows Phone";
    OperatingSystems["WinRT"] = "Windows RT";
    OperatingSystems["Win"] = "Windows";
    OperatingSystems["iOS"] = "iOS";
    OperatingSystems["MacOS"] = "Mac OS X";
    OperatingSystems["Android"] = "Android";
    OperatingSystems["ChromeOS"] = "ChromeOS";
    OperatingSystems["Linux"] = "Linux";
    OperatingSystems["Unknown"] = "Unknown";
})(OperatingSystems = exports.OperatingSystems || (exports.OperatingSystems = {}));
// Yet another OS format name. Currently used for telemetry and download pages
var CanonicalOperatingSystems;
(function (CanonicalOperatingSystems) {
    CanonicalOperatingSystems["Win"] = "windows";
    CanonicalOperatingSystems["Mac"] = "osx";
    CanonicalOperatingSystems["Linux"] = "linux";
    CanonicalOperatingSystems["Other"] = "other";
})(CanonicalOperatingSystems = exports.CanonicalOperatingSystems || (exports.CanonicalOperatingSystems = {}));
// IMPORTANT: the order these items are defined in matters!
var Browsers;
(function (Browsers) {
    Browsers["Opera"] = "(OPR/|Opera)";
    Browsers["Edge"] = "Edge";
    Browsers["EdgeChromium"] = "Edg";
    Browsers["Chrome"] = "Chrome";
    Browsers["Firefox"] = "Firefox";
    Browsers["Safari"] = "Safari";
    Browsers["Unknown"] = "Unknown";
})(Browsers = exports.Browsers || (exports.Browsers = {}));
var DeviceTypes;
(function (DeviceTypes) {
    DeviceTypes["Desktop"] = "Desktop";
    DeviceTypes["Mobile"] = "Mobile";
    DeviceTypes["Tablet"] = "Tablet";
})(DeviceTypes = exports.DeviceTypes || (exports.DeviceTypes = {}));
var BuildEnvironment;
(function (BuildEnvironment) {
    BuildEnvironment["Dev"] = "dev";
    BuildEnvironment["Prod"] = "prod";
    BuildEnvironment["Cypress"] = "cypress";
    BuildEnvironment["Gcchigh"] = "gcchigh";
    BuildEnvironment["Dod"] = "dod";
    BuildEnvironment["Gallatin"] = "gallatin";
    BuildEnvironment["Ag09"] = "ag09";
    BuildEnvironment["Ag08"] = "ag08";
    BuildEnvironment["Life"] = "life";
    BuildEnvironment["Other"] = "other";
})(BuildEnvironment = exports.BuildEnvironment || (exports.BuildEnvironment = {}));
