"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configurationService = exports.ConfigurationService = void 0;
const Environment_1 = require("../models/Environment");
const BaseConfig_1 = require("../config/BaseConfig");
const Config_1 = require("../config/cypress/Config");
const Config_2 = require("../config/development/Config");
const Config_3 = require("../config/dod/Config");
const Config_4 = require("../config/gallatin/Config");
const Config_5 = require("../config/gcchigh/Config");
const Config_6 = require("../config/life/Config");
const Config_7 = require("../config/production/Config");
const Config_8 = require("../config/ag08/Config");
const Config_9 = require("../config/ag09/Config");
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const Logger_1 = require("./Logger");
class ConfigurationService {
    init(env) {
        this.config = BaseConfig_1.default;
        const environmentConfig = this.pickConfigBasedOnEnv(env);
        this.setConfigurationBasedOnPDSEnv(environmentConfig);
    }
    getConfig() {
        if (!this.config) {
            const errorEvent = new TelemetryEvents_1.GenericErrorEvent({
                message: "ConfigurationService has not been initialized",
                error: "configurationServiceError",
            });
            Logger_1.default.log(errorEvent);
            throw new Error("ConfigurationService has not been initialized");
        }
        return this.config;
    }
    setConfigurationBasedOnPDSEnv(envConfig) {
        this.config = Object.assign(Object.assign({}, BaseConfig_1.default), envConfig);
    }
    pickConfigBasedOnEnv(env) {
        switch (env) {
            case Environment_1.BuildEnvironment.Cypress:
                return Config_1.default;
            case Environment_1.BuildEnvironment.Dev:
                return Config_2.default;
            case Environment_1.BuildEnvironment.Dod:
                return Config_3.default;
            case Environment_1.BuildEnvironment.Gallatin:
                return Config_4.default;
            case Environment_1.BuildEnvironment.Gcchigh:
                return Config_5.default;
            case Environment_1.BuildEnvironment.Ag08:
                return Config_8.default;
            case Environment_1.BuildEnvironment.Ag09:
                return Config_9.default;
            case Environment_1.BuildEnvironment.Life:
                return Config_6.default;
            case Environment_1.BuildEnvironment.Prod:
                return Config_7.default;
            case Environment_1.BuildEnvironment.Other:
            default:
                return BaseConfig_1.default;
        }
    }
}
exports.ConfigurationService = ConfigurationService;
const configurationService = new ConfigurationService();
exports.configurationService = configurationService;
