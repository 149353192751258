"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const adaptive_ui_1 = require("@microsoft/adaptive-ui");
const fast_colors_1 = require("@microsoft/fast-colors");
const getBrandedColors = (fastPlaceholder, themeColor) => {
    // Reference color source: https://domoreexp.visualstudio.com/DefaultCollection/Teamspace/_git/teams-modular-packages?path=/packages/components/components-calling-meeting-branding-provider/src/calling-meeting-branding-fast-utils.tsx&version=GBmaster&line=254&lineEnd=257&lineStartColumn=5&lineEndColumn=69&lineStyle=plain&_a=contents
    const REFERENCE_COLOR = (0, fast_colors_1.parseColorHexRGB)("#F5F5F5");
    const input = (0, fast_colors_1.parseColorHexRGB)(themeColor);
    const swatch = adaptive_ui_1.SwatchRGB.from(input);
    adaptive_ui_1.accentBaseSwatch.withDefault(swatch);
    adaptive_ui_1.fillColor.withDefault(adaptive_ui_1.SwatchRGB.from(REFERENCE_COLOR));
    const background = adaptive_ui_1.accentFillRest.getValueFor(fastPlaceholder).toColorString();
    const foregroundActive = adaptive_ui_1.accentForegroundActive.getValueFor(fastPlaceholder).toColorString();
    const backgroundHover = adaptive_ui_1.accentForegroundHover.getValueFor(fastPlaceholder).toColorString();
    const backgroundPressed = adaptive_ui_1.accentForegroundActive.getValueFor(fastPlaceholder).toColorString();
    return {
        background,
        backgroundHover,
        backgroundPressed,
        foregroundActive,
    };
};
exports.default = getBrandedColors;
