"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnsupportedView = void 0;
const React = require("react");
const TeamsLogo_1 = require("../TeamsLogo");
const Translate_1 = require("../Translate");
const View_1 = require("../View");
class UnsupportedView extends React.Component {
    render() {
        return (React.createElement(View_1.View, { className: "unsupportedView" },
            React.createElement("div", { className: "header column centered" },
                React.createElement(TeamsLogo_1.TeamsLogo, null)),
            React.createElement("div", null,
                React.createElement("p", null,
                    React.createElement(Translate_1.Translate, { i18n: "hdr_unsupported_device_message" })),
                React.createElement("p", null,
                    React.createElement(Translate_1.Translate, { i18n: "txt_unsupported_device_advice" })))));
    }
}
exports.UnsupportedView = UnsupportedView;
