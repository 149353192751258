"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ufd = void 0;
const React = require("react");
const Environment_1 = require("../../models/Environment");
const FadeIn_1 = require("./FadeIn");
const Translate_1 = require("./Translate");
require("./Ufd.scss");
class Ufd extends React.PureComponent {
    render() {
        const promptClassName = this.props.img
            ? "top center"
            : this._getPromptClassName(this.props.browser);
        const { renderText = true } = this.props;
        return (React.createElement(FadeIn_1.FadeIn, { key: "ufd" },
            React.createElement("div", { id: "ufd", className: `ufd ${promptClassName}`, role: "alert", "aria-atomic": "true", "aria-live": "polite" },
                React.createElement("div", { className: "ufdWrapper" },
                    this.props.img ? (React.createElement("span", { className: "imgWrapper" },
                        React.createElement("img", { src: this.props.img, "aria-hidden": true }))) : null,
                    React.createElement("div", { className: "textWrapper" },
                        React.createElement("p", { className: "title" },
                            React.createElement(Translate_1.Translate, { i18n: this.props.title || "hdr_coachmark_post_download" })),
                        React.createElement("p", { className: "msg" }, renderText && (React.createElement(Translate_1.Translate, { i18n: this.props.text || "txt_coachmark_post_download" }))))))));
    }
    _getPromptClassName(browser) {
        switch (browser) {
            case Environment_1.Browsers.Safari:
                return "top right";
            case Environment_1.Browsers.Firefox:
            case Environment_1.Browsers.EdgeChromium:
            case Environment_1.Browsers.Opera:
                return "top center";
            case Environment_1.Browsers.Edge:
                return "bottom upper center";
            default:
                return "bottom left";
        }
    }
}
exports.Ufd = Ufd;
