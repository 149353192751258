"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable @typescript-eslint/no-explicit-any */
class ObjectHelper {
    static mergeObjects(...objects) {
        return objects.reduce((previousValue, currentValue) => ObjectHelper._mergeTwo(previousValue, currentValue), undefined);
    }
    static _mergeTwo(ref, toMerge) {
        if (!toMerge || !ref) {
            return ref || toMerge;
        }
        const result = JSON.parse(JSON.stringify(ref));
        Object.keys(ref).forEach(property => {
            if (typeof ref[property] === "function") {
                result[property] = ref[property];
            }
        });
        Object.keys(toMerge).forEach(property => {
            if (typeof ref[property] === "object" && typeof toMerge[property] === "object") {
                result[property] = ObjectHelper._mergeTwo(ref[property], toMerge[property]);
            }
            else {
                result[property] = toMerge[property];
            }
        });
        return result;
    }
}
exports.default = ObjectHelper;
