"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppStoreButton = void 0;
const React = require("react");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const CoreContext_1 = require("../../contexts/CoreContext");
const DownloadService_1 = require("../../services/DownloadService");
require("./appStoreButton.scss");
const AppStoreButtonRenderer_1 = require("./AppStoreButtonRenderer");
class AppStoreButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ language, languageBundle, environment, featureFlags, launcherService }) => (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            const downloadBehavior = DownloadService_1.default.getDownloadBehaviour(joinInfo, environment, featureFlags, launcherService, undefined, this.props.shouldCopyLinkToClipboard);
            return (React.createElement(AppStoreButtonRenderer_1.AppStoreButtonRenderer, { requestedLanguage: language, languageBundle: languageBundle, joinInfo: joinInfo, downloadBehavior: downloadBehavior, onClickCallback: this.props.onClickCallback, newUI: this.props.newUI, title: this.props.title, buttonStyle: this.props.buttonStyle }));
        }))));
    }
}
exports.AppStoreButton = AppStoreButton;
