"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModernSimplifiedView = void 0;
const TeamsLogo_1 = require("../common/TeamsLogo");
const teamsLogoImg = require("../../images/v2/logo_teams.svg");
const Header_1 = require("../common/Header");
require("./ModernSimplifiedView.scss");
const React = require("react");
class ModernSimplifiedView extends React.PureComponent {
    constructor(props) {
        var _a;
        super(props);
        this.buttons = (_a = this.props.buttons) === null || _a === void 0 ? void 0 : _a.reduce((r, key) => (Object.assign(Object.assign({}, r), { [key.props["data-tid"]]: key })), {});
    }
    render() {
        var _a, _b, _c;
        return (React.createElement("div", { className: "modernSimplifiedView" },
            React.createElement("div", { className: "mainActionsContent" },
                React.createElement("div", { className: "headerContainer" },
                    React.createElement("div", { className: "logoContainer" },
                        React.createElement(TeamsLogo_1.TeamsLogo, { src: teamsLogoImg }))),
                React.createElement("div", { className: "actionsContainer" },
                    React.createElement(Header_1.Header, { title: this.props.instructionsPanelTitle, text: this.props.instructionsPanelText, isUIv3: true }),
                    this.props.buttons ? (React.createElement("div", { className: "buttonsContainer" }, (_a = this.buttons) === null || _a === void 0 ? void 0 :
                        _a.joinOnWeb,
                        " ", (_b = this.buttons) === null || _b === void 0 ? void 0 :
                        _b.joinInApp)) : null)),
            this.props.buttons ? (React.createElement("div", { className: "secondaryActionsContent" }, (_c = this.buttons) === null || _c === void 0 ? void 0 : _c.download)) : null));
    }
}
exports.ModernSimplifiedView = ModernSimplifiedView;
