"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcsClient = void 0;
const lodash_1 = require("lodash");
const PromisesHelper_1 = require("../helpers/PromisesHelper");
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const Logger_1 = require("../services/Logger");
const Utils_1 = require("../helpers/Utils");
const ConfigurationService_1 = require("./ConfigurationService");
const defaultEcsFilters = {};
class EcsClient {
    getFlags(ecsFilters = defaultEcsFilters) {
        Logger_1.default.log(new TelemetryEvents_1.EcsClientEvent({ type: "loading" }));
        const ecsConfig = ConfigurationService_1.configurationService.getConfig().ecs;
        const params = this.buildParam(ecsFilters);
        const url = encodeURI(`${ecsConfig.host}/config/v1/${ecsConfig.clientName}/${ecsConfig.clientVersion}?agents=${ecsConfig.projectTeam}${params}`);
        const promise = PromisesHelper_1.default.timeout(fetch(url), ConfigurationService_1.configurationService.getConfig().ecs.timeout);
        return promise
            .then((value) => value.json())
            .then(data => {
            const configIDs = (0, lodash_1.get)(data, `ConfigIDs.${ecsConfig.projectTeam}`, "");
            const flags = (0, lodash_1.get)(data, `${ecsConfig.projectTeam}.RingSettings.Launcher`, {});
            Logger_1.default.log(new TelemetryEvents_1.EcsClientEvent({
                type: "loaded",
                configIDs,
            }));
            return {
                flags,
                configIDs,
            };
        })
            .catch(caughtError => {
            if (caughtError) {
                caughtError.message = Utils_1.Utils.scrubEuii(caughtError.message);
                caughtError.error = Utils_1.Utils.scrubEuii(caughtError.error);
                Logger_1.default.log(new TelemetryEvents_1.EcsClientErrorEvent(caughtError));
            }
            return {
                configIDs: "",
                flags: {},
            };
        });
    }
    buildParam(ecsFilters) {
        const params = Object.entries(ecsFilters)
            .map(([key, value]) => {
            if (value) {
                return `${encodeURI(key)}=${encodeURI(value)}`;
            }
            return;
        })
            .filter(v => v)
            .join("&");
        if (!params.length) {
            return "";
        }
        return `&${params}`;
    }
}
exports.EcsClient = EcsClient;
const ecsClient = new EcsClient();
exports.default = ecsClient;
