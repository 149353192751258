"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadStep = void 0;
var DownloadStep;
(function (DownloadStep) {
    DownloadStep[DownloadStep["Init"] = 0] = "Init";
    DownloadStep[DownloadStep["InProgress"] = 1] = "InProgress";
    DownloadStep[DownloadStep["PotentiallyCompleted"] = 2] = "PotentiallyCompleted";
    DownloadStep[DownloadStep["Intermediate"] = 3] = "Intermediate";
})(DownloadStep = exports.DownloadStep || (exports.DownloadStep = {}));
