"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoDownloader = exports.autoDownloadAttemptStorageKey = void 0;
const React = require("react");
const UrlHelper_1 = require("../../helpers/UrlHelper");
exports.autoDownloadAttemptStorageKey = "hasTriggeredAutoDownload";
class AutoDownloader extends React.PureComponent {
    componentDidMount() {
        var _a;
        if (this.props.trigger) {
            this._preventAutoDownloadOnRefresh(this.props.featureFlags.suppressAutoDownload);
            this.props.autoDownloadCallback(); // reports a automatic download trigger to update state
            this._markAsTriggered(); // stores attempt on localstorage
            (_a = this.props.downloadBehavior) === null || _a === void 0 ? void 0 : _a.onClick(true); // starts download
        }
    }
    render() {
        return null;
    }
    _preventAutoDownloadOnRefresh(isSuppressed) {
        var _a, _b;
        if (isSuppressed) {
            return;
        }
        // Avoiding unwanted "autoLaunch" when browser refresh the tab, recover from previously killed session, etc..
        const supressAutoDownloadUrl = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(window.location.href, "suppressAutoDownload", "true");
        // for browsers not supporting history.pushState (IE9) we don't suppress the prompt
        // Appending QSP without refresh
        (_b = (_a = window.history).pushState) === null || _b === void 0 ? void 0 : _b.call(_a, {}, "", supressAutoDownloadUrl);
    }
    _markAsTriggered() {
        localStorage.setItem(exports.autoDownloadAttemptStorageKey, "true");
    }
}
exports.AutoDownloader = AutoDownloader;
