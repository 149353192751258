"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-bitwise */
/* eslint-disable @microsoft/sdl/no-cookies */
const lodash_1 = require("lodash");
class Utils {
    // this method and all its regexps are copied from TSW's utilityService.ts
    // https://domoreexp.visualstudio.com/DefaultCollection/Teamspace/_git/Teamspace-Web?path=%2Fmodules%2Fsrc%2Fmsteams-web-ng1-services%2Fsrc%2Fservices%2Futility%2FutilityService.ts&_a=contents&version=GBdevelop
    static scrubEuii(logString) {
        if ((0, lodash_1.isNil)(logString)) {
            return logString;
        }
        let retString = logString;
        (0, lodash_1.keys)(this.piiTypeRegexMap).forEach((key) => {
            retString = retString.replace(Utils.piiTypeRegexMap[key], `${key}-pii`);
        });
        return retString;
    }
    static scrubPasscode(logString) {
        const passcodeKVRegex = new RegExp('("passcode":\\s?")([a-zA-Z0-9]*)', "g");
        const passcodeReplacementValue = "****";
        const emptyPasscodeReplacementValue = "";
        // Get all the regex match groups
        const regexMatches = passcodeKVRegex.exec(logString);
        if (!!regexMatches) {
            const replacement = regexMatches.length > 2 && regexMatches[2].length === 0 // Check if passcode is an empty value
                ? emptyPasscodeReplacementValue
                : passcodeReplacementValue;
            // Matches the passcode, keeps the passcode prefix and replace the actual passcode value. For example:
            // {"meetingId": "369", "passcode": "123456"} => {"meetingId": "369", "passcode": "****"}
            // {"meetingId": "369", "passcode": ""} => {"meetingId": "369", "passcode": ""}
            logString = logString.replace(passcodeKVRegex, `$1${replacement}`);
        }
        return logString;
    }
    static scrubInvalidGuid(input) {
        if (input && !this.isGuid(input)) {
            return "Invalid GUID";
        }
        return input;
    }
    static isTabVisible() {
        return document.visibilityState === "visible";
    }
    // TODO: Accessing cookies from document is prohibited and must be reworked to follow security rules
    static returnDocumentCookie() {
        return document.cookie;
    }
    // TODO: Creating cookies from document is prohibited and must be reworked to follow security rules
    static setDocumentCookie(cookie) {
        document.cookie = cookie;
    }
    static copyTextToMobileClipboard(text) {
        var _a, _b;
        let textarea;
        let copiedSuccessfully;
        try {
            textarea = window.document.createElement("textarea");
            textarea.readOnly = true;
            textarea.contentEditable = "true";
            textarea.style.position = "fixed";
            textarea.value = text;
            window.document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            const range = window.document.createRange();
            range.selectNode(textarea);
            const selection = (_b = (_a = window.document).getSelection) === null || _b === void 0 ? void 0 : _b.call(_a);
            selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
            selection === null || selection === void 0 ? void 0 : selection.addRange(range);
            textarea.setSelectionRange(0, textarea.value.length);
            copiedSuccessfully = window.document.execCommand("copy");
        }
        catch (error) {
            copiedSuccessfully = false;
        }
        finally {
            textarea === null || textarea === void 0 ? void 0 : textarea.remove();
        }
        return copiedSuccessfully;
    }
    static isTruthy(val) {
        return val === true || val === "true";
    }
    static parseNumber(val) {
        const isEmptyStr = val === "";
        const isNotNumeric = !Number(val);
        const isBool = typeof val === "boolean";
        if (isEmptyStr || isNotNumeric || isBool) {
            return;
        }
        return Number(val);
    }
    static protocolDetectionSequence() {
        return new Promise(resolve => {
            const appHasFocus = document.hasFocus();
            // eslint-disable-next-line prefer-const
            const callback = (cb) => {
                if (typeof cb === "function") {
                    cb();
                }
            };
            // eslint-disable-next-line prefer-const
            let timeout;
            const blurHandler = () => {
                window.clearTimeout(timeout);
                window.removeEventListener("blur", blurHandler);
                callback(() => {
                    resolve({ isDetected: true, appHasFocus });
                });
            };
            const timeoutHandler = () => {
                window.removeEventListener("blur", blurHandler);
                callback(() => {
                    resolve({ isDetected: false, appHasFocus });
                });
            };
            window.addEventListener("blur", blurHandler);
            timeout = window.setTimeout(timeoutHandler, 3000);
        });
    }
    static genericTimeout(callback, timeout) {
        return window.setTimeout(callback, timeout);
    }
    static fetchImage(img) {
        // This function ensures that an image (from a URL) is cached before actually being displayed/used anywhere on the webpage
        // In the case of branding, ALL images must be displayed at the exact same time
        return new Promise((resolve, reject) => {
            const imageElement = new Image();
            imageElement.onload = () => resolve(img.url);
            imageElement.onerror = () => reject(`${img.id}`);
            imageElement.src = img.url;
        });
    }
    static fetchImages(imgs) {
        // This function calls the Utils.fetchImage utility for all the specified URLs i.e. all the images are cached
        // It returns a fulfilled promise only if all images load successfully
        const imageLoadPromises = [];
        imgs.map(img => {
            // Attempt to load the image only if the URL is not empty
            if (img.url) {
                imageLoadPromises.push(this.fetchImage(img));
            }
        });
        return Promise.all(imageLoadPromises);
    }
    static getElementByClassName(className) {
        // Returns the first instance of an element in the DOM (if it exists) containing the specified className
        const elementsWithClassName = document.getElementsByClassName(className);
        if (elementsWithClassName.length > 0) {
            return elementsWithClassName[0];
        }
        else {
            throw new Error(`${className} not found`);
        }
    }
    static applyImageToContainer(imageURL, containerName) {
        const element = this.getElementByClassName(containerName);
        if (element) {
            element.style.backgroundImage = `url(${imageURL})`;
        }
    }
    static applyHrefToLinkContainer(href, containerName) {
        const element = this.getElementByClassName(containerName);
        if (element && element.href) {
            element.href = href;
        }
    }
    static addClassToContainer(className, containerName) {
        const element = this.getElementByClassName(containerName);
        if (element) {
            element.classList.add(className);
        }
    }
    static logsCollectorKeyCombination(event) {
        // ctrl+alt+shift+1 || cmd+option+shift+1
        return ((event.ctrlKey || event.metaKey) && event.altKey && event.shiftKey && event.code === "Digit1");
    }
    static downloadLogsCollectorFile(logs) {
        const blob = new Blob([logs], { type: "text/plain;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "join_launcher_diagnostic_logs.txt";
        a.click();
    }
}
exports.Utils = Utils;
Utils.fileRegex = new RegExp("(\\b.{5,11}[.]((doc[x]?)|(xls[x]?)|(ppt[x]?)|(pdf))\\b)", "g");
Utils.emailRegex = new RegExp("(\\b([^.P]\\w{5,10}[^30\\s](@|%40)[^aefpstuU<*6]\\w{1,10}[.].{2,3})|([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{3}\\b))", "g");
Utils.phoneRegex = new RegExp("((tel:\\s?[(\\d])|([+]?\\d{1,3}\\s?[(]\\d{3}[)][\\s]\\d{3}[-]\\d{4})|([(]\\d{3}[)][\\s]?\\d{3}[-]\\d{4})|(\\d{3}[-]\\d{3}[-]\\d{4}\\b)|(4:\\+\\d{8,12}))", "g");
Utils.ipAddressRegex = new RegExp("(\\s[1-2]?[\\d]?[\\d][.][1-2]?[\\d]?[\\d][.][1-2]?[\\d]?[\\d][.][1-2]?[\\d]?[\\d][:][^148])", "g");
Utils.ssnRegex = new RegExp("(\\d{3}[-]\\d{2}[-]\\d{4})", "g");
Utils.creditCardRegex = new RegExp("(\\d{4}\\s\\d{4}\\s\\d{4}\\s\\d{4})", "g");
Utils.piiTypeRegexMap = {
    ["file"]: Utils.fileRegex,
    ["email"]: Utils.emailRegex,
    ["phone"]: Utils.phoneRegex,
    ["ipAddress"]: Utils.ipAddressRegex,
    ["ssn"]: Utils.ssnRegex,
    ["creditCard"]: Utils.creditCardRegex,
};
Utils.generateGuid = () => {
    const r = new Array(8);
    for (let i = 0; i < r.length; i += 2) {
        const val = Math.floor(Math.random() * 0x100000000);
        r[i] = Utils.formatAsHex(val & 0xffff);
        r[i + 1] = Utils.formatAsHex(val >>> 16);
        if (i + 1 === 3) {
            // RFC4122 requires a 4 in this position
            r[i + 1] = `4${r[i + 1].substring(1)}`;
        }
        if (i === 4) {
            // RFC4122 requires [089ab] hex values in the first position
            r[i] = ((val & 0x3) | 0x8).toString(16) + r[i].substring(1);
        }
    }
    return `${r[0] + r[1]}-${r[2]}-${r[3]}-${r[4]}-${r[5]}${r[6]}${r[7]}`;
};
Utils.isGuid = (subject) => {
    const guidRegEx = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
    return guidRegEx.test(subject);
};
Utils.generateHash = (input) => {
    let hash = 0;
    if (input.length === 0) {
        return hash;
    }
    for (let i = 0; i < input.length; i += 1) {
        const char = input.charCodeAt(i);
        // tslint:disable-next-line: no-bitwise
        hash = (hash << 5) - hash + char;
        // tslint:disable-next-line: no-bitwise
        hash = hash & hash;
    }
    return hash;
};
Utils.stripQSPFromUrl = (input) => {
    if (input && input.includes("launcher.html")) {
        return input.split("?")[0];
    }
    else {
        return input;
    }
};
Utils.formatAsHex = (n) => {
    let hex = n.toString(16);
    const padding = 4 - hex.length;
    for (let i = 0; i < padding; i += 1) {
        hex = `0${hex}`;
    }
    return hex;
};
