"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spacer = void 0;
const React = require("react");
require("./spacer.scss");
class Spacer extends React.PureComponent {
    render() {
        return React.createElement("div", { className: "spacer" });
    }
}
exports.Spacer = Spacer;
