"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
const React = require("react");
require("./footer.scss");
const Translate_1 = require("./Translate");
const View_1 = require("./View");
const microsoftLogoImg = require("../../images/v3/microsoft_logo.svg");
const disclaimerWarningImg = require("../../images/v3/disclaimer_warning.svg");
const ConfigurationService_1 = require("../../services/ConfigurationService");
const Tooltip_1 = require("./Tooltip");
const CoreContext_1 = require("../../contexts/CoreContext");
class Footer extends React.PureComponent {
    render() {
        const { className = "footer", disclaimer, onLinkClick, showTeamsLogo } = this.props;
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment }) => (React.createElement("div", { className: className, role: "contentinfo" },
            React.createElement(View_1.View, { className: "centered" },
                showTeamsLogo ? (React.createElement("span", { className: "disclaimerTextWithLogo" },
                    React.createElement("img", { src: microsoftLogoImg, "aria-hidden": true }),
                    React.createElement("span", { className: "hideText" },
                        React.createElement(Translate_1.Translate, { i18n: "txt_link_footer_microsoft_teams" })))) : null,
                React.createElement("a", { href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.privacyStatement, target: "_blank", rel: "noopener noreferrer", onClick: onLinkClick, className: "privacyStatementLink" },
                    React.createElement(Translate_1.Translate, { i18n: "txt_link_footer_privacy_and_cookies" })),
                environment.isTflEnvironment ? (React.createElement("a", { href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.consumerHealthPrivacy, target: "_blank", rel: "noopener noreferrer", "data-tid": "consumerPrivacy", onClick: onLinkClick },
                    React.createElement(Translate_1.Translate, { i18n: "txt_link_footer_consumer_health_privacy" }))) : undefined,
                React.createElement("a", { href: "attribution.txt", target: "_blank", rel: "noopener noreferrer", onClick: onLinkClick },
                    React.createElement(Translate_1.Translate, { i18n: "txt_link_footer_third_party_disclosures" })),
                disclaimer ? (React.createElement("span", null,
                    " ",
                    "| ",
                    React.createElement(Translate_1.Translate, { i18n: disclaimer }))) : null,
                this.props.showBrandingDisclaimer ? (React.createElement(Tooltip_1.Tooltip, { text: "txt_link_footer_branding_disclaimer_tooltip" },
                    React.createElement("span", { className: "disclaimerTextWithLogo" },
                        React.createElement("span", { className: "hideText" },
                            React.createElement(Translate_1.Translate, { i18n: "txt_link_footer_branding_disclaimer" })),
                        React.createElement("img", { src: disclaimerWarningImg, "aria-hidden": true })))) : null)))));
    }
}
exports.Footer = Footer;
