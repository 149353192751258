"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunityEventsHelper = void 0;
const JoinInfo_1 = require("../models/JoinInfo");
const TranslationService_1 = require("../services/TranslationService");
const UrlHelper_1 = require("./UrlHelper");
/**
 * Community events helper class
 * - contains shared functions used by web and mobile platforms to not duplicate logic
 */
class CommunityEventsHelper {
    /** Community event is meet link with eventType query param 'community'*/
    static isCommunityEvent(joinInfo) {
        return (joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode &&
            joinInfo.deepLinkUrl.indexOf("eventType=community") !== -1);
    }
    /** Approximation for community event based on eventType query param 'community'*/
    static isCommunityEventByUrl(deepLinkUrl) {
        return deepLinkUrl.indexOf("eventType=community") !== -1;
    }
    /** Constructing event description - time part - based on provided context about meeting times */
    static getEventDescriptionTimeLine(context) {
        if (context.startTime && context.endTime) {
            const startDate = new Date(context.startTime);
            const endDate = new Date(context.endTime);
            return `${startDate.toLocaleTimeString(undefined, {
                hour: "numeric",
                hour12: true,
                minute: "2-digit",
            })} - ${endDate.toLocaleTimeString(undefined, {
                hour: "numeric",
                hour12: true,
                minute: "2-digit",
            })}`;
        }
        return undefined;
    }
    /** Constructing event description - date part - based on provided context about meeting times */
    static getEventDescriptionDateLine(context) {
        if (context.startTime) {
            const startDate = new Date(context.startTime);
            return `${startDate.toLocaleDateString(undefined, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            })}`;
        }
        return undefined;
    }
    /**
     * Creating event iCal and returning blob url for download
     *  - iCal is created only if basic information is available on context (title/startTime/endTime)
     * */
    static createEventICalBlob(context, joinInfo) {
        if (context.title && context.startTime && context.endTime) {
            const joinUrl = CommunityEventsHelper.getJoinUrlForCalendar(joinInfo);
            const iCalContent = CommunityEventsHelper.createEventICal(context.title, context.startTime, context.endTime, joinUrl, context.iCalUid);
            const blob = new Blob([iCalContent], { type: "text/calendar" });
            return URL.createObjectURL(blob);
        }
        return undefined;
    }
    static createEventICal(title, startTime, endTime, joinUrl, iCalUid) {
        const icalData = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            "BEGIN:VALARM",
            "TRIGGER:-PT15M",
            "ACTION:DISPLAY",
            "END:VALARM",
            "TRANSP:OPAQUE",
            "PRIORITY:5",
            "SEQUENCE:0",
            "CLASS:PUBLIC",
            "STATUS:CONFIRMED",
            `DTSTART:${CommunityEventsHelper.getICalFormattedDateFromDateTimeString(startTime)}`,
            `DTEND:${CommunityEventsHelper.getICalFormattedDateFromDateTimeString(endTime)}`,
            `DESCRIPTION:${TranslationService_1.default.translate("join_event_link_label")} ${joinUrl}`,
            `SUMMARY:${title}`,
            `LOCATION:${TranslationService_1.default.translate("msft_teams_meeting")}`,
            iCalUid ? `UID:${iCalUid}` : "",
            "END:VEVENT",
            "END:VCALENDAR",
        ];
        return icalData.join("\r\n");
    }
    /**
     * The iCal spec requires dateTimes be formatted in UTC (with a 'Z' suffix)
     * https://www.kanzaki.com/docs/ical/dateTime.html
     * This method converts the dateTime string we get from the service to this format.
     * Steps of conversion: "2023-09-10T07:00:00+00:00" (service datetime string)
     * to 2023-09-10T07:00:00.000Z (ISO datetime string) --> remove the milliseconds
     * to "20230910T070000Z" (iCal UTC format)
     */
    static getICalFormattedDateFromDateTimeString(stringDateTime) {
        const isoDateStringWithoutMs = `${new Date(stringDateTime).toISOString().split(".")[0]}Z`;
        return isoDateStringWithoutMs.replace(/[-:]/g, "");
    }
    static getJoinUrlForCalendar(joinInfo) {
        let url = `${UrlHelper_1.UrlHelper.getOrigin()}${joinInfo.deepLinkUrl}`;
        // Adding launchAgent and laEntry to track usage of this link from calendar
        url = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, "launchAgent", "join_launcher", false);
        url = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, "laEntry", "addToCalendar", false);
        return url;
    }
}
exports.CommunityEventsHelper = CommunityEventsHelper;
