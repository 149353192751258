"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TelemetryScenarioTracker = exports.ScenarioStatuses = exports.ScenarioStepNames = exports.ScenarioNames = void 0;
const webjs_sdk_1 = require("@aria/webjs-sdk");
const Logger_1 = require("./Logger");
const Utils_1 = require("../helpers/Utils");
var ScenarioNames;
(function (ScenarioNames) {
    ScenarioNames["JoinLauncherStart"] = "join_launcher_start";
})(ScenarioNames = exports.ScenarioNames || (exports.ScenarioNames = {}));
var ScenarioStepNames;
(function (ScenarioStepNames) {
    ScenarioStepNames["Start"] = "start";
    ScenarioStepNames["Bootstrap"] = "bootstrap";
    ScenarioStepNames["Stop"] = "stop";
})(ScenarioStepNames = exports.ScenarioStepNames || (exports.ScenarioStepNames = {}));
var ScenarioStatuses;
(function (ScenarioStatuses) {
    ScenarioStatuses["Success"] = "success";
    ScenarioStatuses["Failure"] = "failure";
    ScenarioStatuses["Abandoned"] = "abandoned";
})(ScenarioStatuses = exports.ScenarioStatuses || (exports.ScenarioStatuses = {}));
class TelemetryScenarioTracker {
    static init() {
        if (TelemetryScenarioTracker.appStartTime !== 0) {
            return;
        }
        TelemetryScenarioTracker.appStartTime = Date.now();
    }
    static getOrCreateScenario(name) {
        let scenario = TelemetryScenarioTracker.activeScenarios[name];
        if (!scenario) {
            scenario = new TelemetryScenarioTracker(name);
            TelemetryScenarioTracker.activeScenarios[name] = scenario;
        }
        return scenario;
    }
    constructor(name) {
        this.name = name;
        this.isCompleted = false;
        this.isStarted = false;
    }
    start() {
        if (this.isStarted || this.isCompleted) {
            return;
        }
        this.isStarted = true;
        this.scenarioStartTime = Date.now();
        this.stepStartTime = Date.now();
        this.sendTelemetry(ScenarioStepNames.Start, ScenarioStatuses.Success, undefined, webjs_sdk_1.AWTEventPriority.High);
    }
    markStep(step, status, properties) {
        if (!this.isStarted || this.isCompleted) {
            return;
        }
        this.sendTelemetry(step, status, properties);
    }
    fail(reason) {
        const props = {};
        props.errorDescription = Utils_1.Utils.scrubEuii(reason || "");
        this.completeScenario(ScenarioStatuses.Failure, props);
    }
    stop() {
        this.completeScenario(ScenarioStatuses.Success);
    }
    cancel(reason) {
        const props = {};
        props.errorDescription = reason;
        this.completeScenario(ScenarioStatuses.Abandoned, props);
    }
    completeScenario(status, properties) {
        if (!this.isStarted || this.isCompleted) {
            return;
        }
        delete TelemetryScenarioTracker.activeScenarios[this.name];
        this.isCompleted = true;
        this.sendTelemetry(ScenarioStepNames.Stop, status, properties, webjs_sdk_1.AWTEventPriority.High);
    }
    sendTelemetry(step, status, properties, priority = webjs_sdk_1.AWTEventPriority.Normal) {
        if (!properties) {
            properties = {};
        }
        properties["Scenario.Name"] = this.name;
        properties["Scenario.Status"] = status;
        properties["Scenario.Step"] = step;
        this.enrichWithPerfData(properties);
        Logger_1.default.logScenarioStep({ name: this.name, properties }, priority);
    }
    enrichWithPerfData(properties) {
        properties.stepDelta = Date.now() - this.stepStartTime;
        properties.delta = Date.now() - this.scenarioStartTime;
        properties.elapsed = Date.now() - TelemetryScenarioTracker.appStartTime;
        if (!this.isCompleted) {
            // reset step start time
            this.stepStartTime = Date.now();
        }
    }
}
exports.TelemetryScenarioTracker = TelemetryScenarioTracker;
TelemetryScenarioTracker.appStartTime = 0;
TelemetryScenarioTracker.activeScenarios = {};
