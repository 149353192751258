"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusyAnimation = void 0;
const React = require("react");
const busyAnimation = require("../../../images/v2/busy_animation.svg");
require("./BusyAnimation.scss");
class BusyAnimation extends React.PureComponent {
    render() {
        return (React.createElement("div", { className: "busy-animation" },
            React.createElement("div", { className: `ts-spinner-internal size-${this.props.size}x` },
                React.createElement("div", { className: "ts-spinner-container" },
                    React.createElement("div", { className: "spinner-animate" },
                        React.createElement("img", { src: busyAnimation }))))));
    }
}
exports.BusyAnimation = BusyAnimation;
