"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerParamsService = void 0;
const Environment_1 = require("../models/Environment");
const LogsCollectorService_1 = require("./LogsCollectorService");
class ServerParamsService {
    init() {
        return this.getServerParams();
    }
    getServerParams() {
        if (!this.params) {
            this.params = this.computeServerParams();
        }
        LogsCollectorService_1.default.logServerParams(this.params);
        return this.params;
    }
    computeServerParams() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        const params = {
            environment: Environment_1.BuildEnvironment.Other,
            ring: undefined,
            anonymous: false,
            featureFlags: {},
            requestContext: { sessionId: "" },
        };
        if (Object.prototype.hasOwnProperty.call(window, "SERVER_PARAMS")) {
            params.ring = (SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.ring) || undefined;
            params.anonymous = (SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.anonymous) || false;
            params.environment =
                (SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.environment) || Environment_1.BuildEnvironment.Other;
            const featureFlags = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.featureFlags;
            if (featureFlags) {
                params.featureFlags = featureFlags;
            }
            // Previously existing request parameter
            params.requestContext.sessionId = ((_a = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _a === void 0 ? void 0 : _a.sessionId) || "";
            // Currently known request context parameters that can be passed from PDS
            // to provide contextual experiences
            // Generic contextual params
            params.requestContext.title = (_b = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _b === void 0 ? void 0 : _b.title;
            params.requestContext.description = (_c = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _c === void 0 ? void 0 : _c.description;
            params.requestContext.avatarImage = (_d = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _d === void 0 ? void 0 : _d.avatarImage;
            // Community context params
            params.requestContext.guidelines = (_e = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _e === void 0 ? void 0 : _e.guidelines;
            params.requestContext.membersCount = (_f = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _f === void 0 ? void 0 : _f.membersCount;
            // Community event context params
            params.requestContext.meetingType = (_g = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _g === void 0 ? void 0 : _g.meetingType;
            params.requestContext.eventType = (_h = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _h === void 0 ? void 0 : _h.eventType;
            params.requestContext.startTime = (_j = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _j === void 0 ? void 0 : _j.startTime;
            params.requestContext.endTime = (_k = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _k === void 0 ? void 0 : _k.endTime;
            params.requestContext.communityThreadId = (_l = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _l === void 0 ? void 0 : _l.communityThreadId;
            params.requestContext.exchangeId = (_m = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _m === void 0 ? void 0 : _m.exchangeId;
            params.requestContext.iCalUid = (_o = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _o === void 0 ? void 0 : _o.iCalUid;
            params.requestContext.eventRecurrenceRange = this.safeParseJson((_p = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _p === void 0 ? void 0 : _p.eventRecurrenceRange);
            params.requestContext.eventRecurrencePattern = this.safeParseJson((_q = SERVER_PARAMS === null || SERVER_PARAMS === void 0 ? void 0 : SERVER_PARAMS.requestContext) === null || _q === void 0 ? void 0 : _q.eventRecurrencePattern);
        }
        return params;
    }
    safeParseJson(value) {
        if (!value) {
            return undefined;
        }
        try {
            return JSON.parse(value);
        }
        catch (parseError) {
            return undefined;
        }
    }
}
exports.ServerParamsService = ServerParamsService;
const serverParamsService = new ServerParamsService();
exports.default = serverParamsService;
