"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinMobileView = void 0;
const React = require("react");
const teamsIllustrationImg = require("../../images/illustration.svg");
const CoreContext_1 = require("../../contexts/CoreContext");
const Environment_1 = require("../../models/Environment");
const AppStoreButton_1 = require("../common/AppStoreButton");
const Footer_1 = require("../common/Footer");
const Spacer_1 = require("../common/Spacer");
const TeamsLogo_1 = require("../common/TeamsLogo");
const Translate_1 = require("../common/Translate");
const View_1 = require("../common/View");
require("./joinMobileView.scss");
class JoinMobileView extends React.Component {
    render() {
        return (React.createElement(View_1.View, { className: "joinMobileView" },
            React.createElement("div", { className: "body column centered" },
                React.createElement(Spacer_1.Spacer, null),
                React.createElement(TeamsLogo_1.TeamsLogo, null),
                React.createElement("div", { className: "message" },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_mobile_welcome" })),
                React.createElement(CoreContext_1.CoreContextConsumer, null, ({ featureFlags }) => !featureFlags.hideDownloadButton && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "message bold" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_mobile_acquire_app" })),
                    React.createElement(AppStoreButton_1.AppStoreButton, null)))),
                React.createElement(Spacer_1.Spacer, null),
                React.createElement("div", { className: "message" },
                    React.createElement("div", null,
                        React.createElement(Translate_1.Translate, { i18n: "hdr_mobile_try_launch_again" })),
                    React.createElement("a", { className: "launchLink", id: "tryMobileProtocolLaunch", href: "#", onClick: this.props.onLaunchClick },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_mobile_launch_app" }))),
                React.createElement(Spacer_1.Spacer, null),
                React.createElement("img", { className: "illustration", src: teamsIllustrationImg }),
                React.createElement(Spacer_1.Spacer, null),
                React.createElement("div", { className: "legalNotice" },
                    React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment, featureFlags }) => !featureFlags.hideDownloadButton && (React.createElement(React.Fragment, null,
                        environment.os === Environment_1.OperatingSystems.Android && (React.createElement(Translate_1.Translate, { i18n: "hdr_disclaimer_android" })),
                        environment.os === Environment_1.OperatingSystems.iOS && (React.createElement(Translate_1.Translate, { i18n: "hdr_disclaimer_apple" }))))))),
            React.createElement(Footer_1.Footer, null)));
    }
}
exports.JoinMobileView = JoinMobileView;
