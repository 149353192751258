"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorBoundary = void 0;
const webjs_sdk_1 = require("@aria/webjs-sdk");
const React = require("react");
const UrlHelper_1 = require("../../helpers/UrlHelper");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const Logger_1 = require("../../services/Logger");
const TelemetryScenarioTracker_1 = require("../../services/TelemetryScenarioTracker");
const Utils_1 = require("../../helpers/Utils");
const ConfigurationService_1 = require("../../services/ConfigurationService");
class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error, errorInfo) {
        const errorEvent = new TelemetryEvents_1.GenericErrorEvent({
            message: Utils_1.Utils.scrubEuii(error.message),
            error: Utils_1.Utils.scrubEuii(JSON.stringify(errorInfo)),
        });
        Logger_1.default.log(errorEvent, webjs_sdk_1.AWTEventPriority.Immediate_sync);
        // in case the start scenario is active, fail it; otherwise, this code will not send an event
        const scenario = TelemetryScenarioTracker_1.TelemetryScenarioTracker.getOrCreateScenario(TelemetryScenarioTracker_1.ScenarioNames.JoinLauncherStart);
        scenario.fail(`Error Boundary caught: ${Utils_1.Utils.scrubEuii(error.message)}`);
        this.setState({
            hasError: true,
        });
    }
    render() {
        if (this.state.hasError) {
            UrlHelper_1.UrlHelper.redirect(ConfigurationService_1.configurationService.getConfig().urls.paths.error());
            return null;
        }
        return this.props.children;
    }
}
exports.ErrorBoundary = ErrorBoundary;
