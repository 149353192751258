"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslationService = void 0;
class TranslationService {
    init(languageBundle, language) {
        this.language = language;
        this.languageBundle = languageBundle;
    }
    translate(i18n, languageBundle, language) {
        const computedLanguageBundle = languageBundle || this.languageBundle;
        const computedLanguage = language || this.language;
        if (!computedLanguageBundle || !computedLanguage) {
            throw new Error("Language and bundle must be set");
        }
        return computedLanguageBundle[i18n] || i18n;
    }
}
exports.TranslationService = TranslationService;
const translationService = new TranslationService();
exports.default = translationService;
