"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAndApplyPrivacyStatement = void 0;
const PrivacyStatementService_1 = require("../services/PrivacyStatementService");
const Utils_1 = require("./Utils");
const fetchAndApplyPrivacyStatement = (privacyStatementInput) => {
    PrivacyStatementService_1.privacyStatementService
        .fetchPrivacyStatement(privacyStatementInput)
        .then(url => {
        if (url && url !== "") {
            Utils_1.Utils.applyHrefToLinkContainer(url, "privacyStatementLink");
        }
    })
        .catch(() => {
        // if it fails we default to Microsoft's privacy statement
    });
};
exports.fetchAndApplyPrivacyStatement = fetchAndApplyPrivacyStatement;
