"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConfig_1 = require("../BaseConfig");
const Environment_1 = require("../../models/Environment");
const gallatinConfig = {
    env: Environment_1.BuildEnvironment.Gallatin,
    ariaCollectorUri: "https://teams.events.data.microsoft.com/Collector/3.0/",
    OneDsCollectorUri: "https://teams.events.data.microsoft.com/OneCollector/1.0/",
    publicPath: "https://statics-cn.teams.cdn.office.net",
    telemetry: {
        tenantToken: "e890c57bfdae44e4acede01d81b815cd-f4791023-4a89-477e-b906-cc77b0867fce-7097",
    },
    ecs: Object.assign(Object.assign({}, BaseConfig_1.default.ecs), { host: "https://mooncake.config.teams.microsoft.com" }),
};
exports.default = gallatinConfig;
