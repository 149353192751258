"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModernMobileView = void 0;
const React = require("react");
const teamsIllustrationImg = require("../../images/v2/welcome_to_teams.svg");
const tflIllustrationImg = require("../../images/v2/welcome_to_tfl.svg");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const Logger_1 = require("../../services/Logger");
const TranslationService_1 = require("../../services/TranslationService");
const AppStoreButton_1 = require("../common/AppStoreButton");
const Button_1 = require("../common/Button");
const Footer_1 = require("../common/Footer");
const Spacer_1 = require("../common/Spacer");
const Translate_1 = require("../common/Translate");
const View_1 = require("../common/View");
require("./modernMobileView.scss");
const Environment_1 = require("../../models/Environment");
const EnvironmentService_1 = require("../../services/EnvironmentService");
class ModernMobileView extends React.Component {
    constructor(props) {
        super(props);
        this.finalPanelViewReported = false;
        this.state = {
            isCopyToClipboardChecked: !!props.isTflLink,
        };
    }
    render() {
        let illustration;
        let headerFirst;
        let headerSecond;
        let downloadButtonTitle;
        let joinButtonTitle;
        let joinButtonStyle;
        let meetingLinkCopyDescription;
        let downloadButtonDescription;
        let joinButtonDescription;
        let isCopyLinkToClipboardAllowed = false;
        let shouldRenderCopyLinkToClipboardCheckbox = false;
        if (!this.props.isTflLink) {
            illustration = teamsIllustrationImg;
            headerFirst = "hdr_mobile_first";
            headerSecond = "hdr_mobile_second";
            downloadButtonTitle = "btn_mobile_download";
            joinButtonDescription = this.renderMessage("txt_mobile_secondary_button_description");
            joinButtonTitle = "btn_mobile_join_meeting";
            joinButtonStyle = "secondary mobile";
            meetingLinkCopyDescription = "mobile_copy_clipboard_description";
            isCopyLinkToClipboardAllowed = EnvironmentService_1.default.getOS() === Environment_1.OperatingSystems.iOS;
        }
        else {
            illustration = tflIllustrationImg;
            headerFirst = "hdr_mobile_first_conversation";
            headerSecond = "hdr_mobile_second_join_conversation_ios";
            downloadButtonDescription = this.renderMessage("txt_mobile_join_conversation_step1", true);
            downloadButtonTitle = "hdr_mobile_acquire_app";
            joinButtonDescription = this.renderMessage("txt_mobile_join_conversation_step2");
            joinButtonTitle = "btn_mobile_join_conversation";
            joinButtonStyle = "primary mobile";
            shouldRenderCopyLinkToClipboardCheckbox = EnvironmentService_1.default.getOS() === Environment_1.OperatingSystems.iOS;
        }
        return (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            this.reportSuccessPanelView("mobile_join_launcher_loaded", joinInfo);
            return (React.createElement(View_1.View, { className: "modernMobileView" },
                React.createElement("div", { className: "body column centered" },
                    React.createElement("h1", null,
                        React.createElement(Translate_1.Translate, { i18n: headerFirst })),
                    React.createElement("img", { className: "illustration", src: illustration }),
                    React.createElement("div", { className: "message" },
                        React.createElement(Translate_1.Translate, { i18n: headerSecond })),
                    downloadButtonDescription,
                    shouldRenderCopyLinkToClipboardCheckbox &&
                        this.renderCopyClipboardCheckBox("mobile_copy_link_checkbox_label", joinInfo),
                    !this.props.featureFlags.hideDownloadButton && (React.createElement(AppStoreButton_1.AppStoreButton, { newUI: true, onClickCallback: () => {
                            this.reportPanelAction(TelemetryEvents_1.ModuleNames.Download, joinInfo);
                        }, title: TranslationService_1.default.translate(downloadButtonTitle), shouldCopyLinkToClipboard: isCopyLinkToClipboardAllowed ||
                            (shouldRenderCopyLinkToClipboardCheckbox &&
                                this.state.isCopyToClipboardChecked) })),
                    joinButtonDescription,
                    React.createElement(Button_1.Button, { className: joinButtonStyle, "data-tid": "joinInApp", onClick: () => {
                            this.reportPanelAction(TelemetryEvents_1.ModuleNames.JoinInApp, joinInfo);
                            this.props.onLaunchClick();
                        } },
                        React.createElement(Translate_1.Translate, { i18n: joinButtonTitle })),
                    React.createElement(Spacer_1.Spacer, null),
                    isCopyLinkToClipboardAllowed && this.renderMessage(meetingLinkCopyDescription),
                    React.createElement(Spacer_1.Spacer, null)),
                React.createElement(Footer_1.Footer, { onLinkClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.ShowThirdPartyNotice, joinInfo) })));
        }));
    }
    renderMessage(message, spacer) {
        return (React.createElement(React.Fragment, null,
            spacer ? React.createElement(Spacer_1.Spacer, null) : null,
            React.createElement("div", { className: "message" },
                React.createElement(Translate_1.Translate, { i18n: message }))));
    }
    renderCopyClipboardCheckBox(label, joinInfo) {
        return (React.createElement("div", { className: "checkboxContainer" },
            React.createElement("input", { type: "checkbox", className: "checkbox", checked: this.state.isCopyToClipboardChecked, onClick: () => {
                    this.reportPanelAction(TelemetryEvents_1.ModuleNames.CopyLinkToClipboard, joinInfo);
                    this.setState({
                        isCopyToClipboardChecked: !this.state.isCopyToClipboardChecked,
                    });
                } }),
            " ",
            TranslationService_1.default.translate(label)));
    }
    reportSuccessPanelView(name, joinInfo, props) {
        if (!this.finalPanelViewReported) {
            const panelViewEvent = new TelemetryEvents_1.PanelViewEvent(name, joinInfo, Object.assign(Object.assign({}, props), { launchResult: this.props.launchResult }));
            Logger_1.default.logUserBiPanelView(panelViewEvent);
            this.finalPanelViewReported = true;
        }
    }
    reportPanelAction(moduleName, joinInfo) {
        const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernMobileView, moduleName, joinInfo);
        Logger_1.default.logUserBiPanelAction(panelActionEvent);
    }
}
exports.ModernMobileView = ModernMobileView;
