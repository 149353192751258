"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
const React = require("react");
const Translate_1 = require("./Translate");
require("./Header.scss");
const HeadlineH1 = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement("h1", Object.assign({}, props), children));
};
const HeadlineH3 = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement("h3", Object.assign({}, props), children));
};
class Header extends React.PureComponent {
    render() {
        // TODO: remove this logic when UIv2 will get deprecated
        const Headline = this.props.isUIv3 ? HeadlineH3 : HeadlineH1;
        return (React.createElement("div", { className: "content", id: "textbox" },
            this.props.children ? (React.createElement(Headline, { className: this.props.className ? this.props.className : "" }, this.props.children)) : (React.createElement(Headline, null,
                React.createElement(Translate_1.Translate, { i18n: this.props.title }))),
            this.props.text ? (React.createElement("p", null,
                React.createElement(Translate_1.Translate, { i18n: this.props.text }))) : null));
    }
}
exports.Header = Header;
