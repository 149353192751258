"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadPotentiallyCompleted = void 0;
const React = require("react");
const Button_1 = require("./Button");
const FadeIn_1 = require("./FadeIn");
const Translate_1 = require("./Translate");
const ConfigurationService_1 = require("../../services/ConfigurationService");
class DownloadPotentiallyCompleted extends React.PureComponent {
    render() {
        return (React.createElement(FadeIn_1.FadeIn, { className: "column centered" },
            React.createElement("h1", { id: "downloadingProblemBody", className: "title" },
                React.createElement(Translate_1.Translate, { i18n: "hdr_post_download" })),
            React.createElement("p", { className: "desktopMessage" },
                React.createElement("span", null,
                    React.createElement(Translate_1.Translate, { i18n: this.props.environment.isTflEnvironment
                            ? "txt_post_download_tfl"
                            : "txt_post_download" })),
                "\u00A0",
                React.createElement("a", { className: "launchLink", id: "downloadDesktopClient3", href: "#", onClick: this.props.onDownloadClick },
                    React.createElement(Translate_1.Translate, { i18n: "txt_post_download_lnk" }))),
            React.createElement("div", { className: "buttonsContainer" },
                React.createElement(Button_1.Button, { id: "openTeamsClientInBrowser", onClick: this.props.onWebLaunchClick },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_btn_join_on_web" }))),
            React.createElement("div", { className: "desktopMessage" },
                React.createElement("span", null,
                    React.createElement(Translate_1.Translate, { i18n: "hdr_try_again_text" })),
                "\u00A0",
                React.createElement("a", { className: "launchLink", id: "tryProtocolLaunchTeamsClient2", href: "#", onClick: this.props.onLaunchClick },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_lnk_launch_skype" }))),
            React.createElement("div", { className: "desktopMessage" },
                React.createElement("span", null,
                    React.createElement(Translate_1.Translate, { i18n: "txt_post_download_problems" })),
                "\u00A0",
                React.createElement("a", { className: "launchLink", id: "help_link", href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.downloadHelp, target: "_blank", rel: "noopener noreferrer" },
                    React.createElement(Translate_1.Translate, { i18n: "txt_get_more_help" })))));
    }
}
exports.DownloadPotentiallyCompleted = DownloadPotentiallyCompleted;
