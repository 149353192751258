"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TflDesktopCommunityView = void 0;
const React = require("react");
const QRCode = require("qrcode-svg");
const CoreContext_1 = require("../../contexts/CoreContext");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const communityImage = require("../../images/v2/tfl_community_best_friends.svg");
const Environment_1 = require("../../models/Environment");
const DownloadService_1 = require("../../services/DownloadService");
const Logger_1 = require("../../services/Logger");
const AppStoreButtonRenderer_1 = require("../common/AppStoreButtonRenderer");
const Footer_1 = require("../common/Footer");
const TeamsLogo_1 = require("../common/TeamsLogo");
const Translate_1 = require("../common/Translate");
const View_1 = require("../common/View");
require("./TflDesktopCommunityView.scss");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const UrlHelper_1 = require("../../helpers/UrlHelper");
const Utils_1 = require("../../helpers/Utils");
const ConfigurationService_1 = require("../../services/ConfigurationService");
class TflDesktopCommunityView extends React.Component {
    constructor(props) {
        super(props);
        this.finalPanelViewReported = false;
    }
    render() {
        const svgComponent = this.generateQrCode();
        return (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo, downloadState }) => {
            this.reportSuccessPanelView("desktop_community_join_loaded", joinInfo);
            return (React.createElement(View_1.View, { id: "communityView", className: "container", role: "main" },
                React.createElement("div", { className: "header", role: "banner" },
                    React.createElement(TeamsLogo_1.TeamsLogo, null),
                    React.createElement("img", { className: "communityImage", id: "community-image", src: communityImage, "aria-hidden": "true" })),
                React.createElement("div", { className: "details" },
                    React.createElement("div", { className: "title" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_community_introduction_title" }),
                        React.createElement("br", null)),
                    React.createElement("div", { className: "description" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_community_introduction_description" }),
                        "\u00A0",
                        React.createElement("a", { href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.tflCommunitiesLearnMoreLink, target: "_blank", rel: "noopener noreferrer", onClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.LearnMore, joinInfo), id: "tflCommunitiesLearnMore" },
                            React.createElement(Translate_1.Translate, { i18n: "hdr_btn_learn_more" })),
                        React.createElement("br", null)),
                    svgComponent && (React.createElement("div", { className: "qrCodeContainer" },
                        React.createElement("img", { id: "qrCode", src: `data:image/svg+xml;utf8,${svgComponent}` }))),
                    React.createElement(CoreContext_1.CoreContextConsumer, null, ({ language, languageBundle, environment, featureFlags, launcherService }) => {
                        const iosEnv = Object.assign({}, environment);
                        iosEnv.os = Environment_1.OperatingSystems.iOS;
                        const iosDownloadBehavior = DownloadService_1.default.getDownloadBehaviour(joinInfo, iosEnv, featureFlags, launcherService, downloadState);
                        const androidEnv = Object.assign({}, environment);
                        androidEnv.os = Environment_1.OperatingSystems.Android;
                        const androidDownloadBehavior = DownloadService_1.default.getDownloadBehaviour(joinInfo, androidEnv, featureFlags, launcherService, downloadState);
                        return (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "buttonsContainer centered" },
                                React.createElement(AppStoreButtonRenderer_1.AppStoreButtonRenderer, { key: "androidButton", requestedLanguage: language, languageBundle: languageBundle, joinInfo: joinInfo, downloadBehavior: androidDownloadBehavior, onClickCallback: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.DownloadGooglePlay, joinInfo) }),
                                React.createElement(AppStoreButtonRenderer_1.AppStoreButtonRenderer, { key: "iosButton", requestedLanguage: language, languageBundle: languageBundle, joinInfo: joinInfo, downloadBehavior: iosDownloadBehavior, onClickCallback: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.DownloadAppleStore, joinInfo) }))));
                    })),
                React.createElement("div", { className: "bottomSection" },
                    React.createElement("div", { className: "notices" },
                        React.createElement("div", { className: "legalNotice" },
                            React.createElement(Translate_1.Translate, { i18n: "hdr_disclaimer_android" })),
                        React.createElement("div", { className: "legalNotice" },
                            React.createElement(Translate_1.Translate, { i18n: "hdr_disclaimer_apple" }))),
                    React.createElement(Footer_1.Footer, { className: "disclaimer", onLinkClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.ShowThirdPartyNotice, joinInfo) }))));
        }));
    }
    generateQrCode() {
        try {
            let url = `${UrlHelper_1.UrlHelper.getOrigin()}${this.props.joinInfo.deepLinkUrl}`;
            // Adding launchAgent and laEntry to track usage of this QR at mobile join launcher side
            url = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, "launchAgent", "join_launcher", false);
            url = UrlHelper_1.UrlHelper.addOrReplaceQueryParameter(url, "laEntry", "qrcode", false);
            const svg = new QRCode({
                content: url,
                container: "svg",
                join: true,
                ecl: "Q",
                xmlDeclaration: false,
                width: 120,
                height: 120,
            }).svg();
            if (svg) {
                return encodeURIComponent(svg);
            }
        }
        catch (error) {
            Logger_1.default.log(new TelemetryEvents_1.QRCodeGenerationFailedEvent({
                message: Utils_1.Utils.scrubEuii(error.message),
                error: Utils_1.Utils.scrubEuii(JSON.stringify(error)),
            }));
        }
        return undefined;
    }
    reportSuccessPanelView(name, joinInfo, props) {
        if (!this.finalPanelViewReported) {
            const panelViewEvent = new TelemetryEvents_1.PanelViewEvent(name, joinInfo, Object.assign(Object.assign({}, props), { launchResult: this.props.launchResult }));
            Logger_1.default.logUserBiPanelView(panelViewEvent);
            this.finalPanelViewReported = true;
        }
    }
    reportPanelAction(moduleName, joinInfo) {
        const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.CommunityJoinView, moduleName, joinInfo);
        Logger_1.default.logUserBiPanelAction(panelActionEvent);
    }
}
exports.TflDesktopCommunityView = TflDesktopCommunityView;
