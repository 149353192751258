"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadService = exports.DownloadAction = exports.AppStoreType = void 0;
const UrlHelper_1 = require("../helpers/UrlHelper");
const Utils_1 = require("../helpers/Utils");
const DownloadState_1 = require("../models/DownloadState");
const Environment_1 = require("../models/Environment");
const LaunchType_1 = require("../models/LaunchType");
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const Logger_1 = require("./Logger");
const ConfigurationService_1 = require("./ConfigurationService");
var AppStoreType;
(function (AppStoreType) {
    AppStoreType["Google"] = "google";
    AppStoreType["Apple"] = "apple";
    AppStoreType["Unknown"] = "unknown";
})(AppStoreType = exports.AppStoreType || (exports.AppStoreType = {}));
var DownloadAction;
(function (DownloadAction) {
    DownloadAction["DirectDownload"] = "getInstaller";
    DownloadAction["RedirectToDowloadsPage"] = "redirectToDowloadsPage";
})(DownloadAction = exports.DownloadAction || (exports.DownloadAction = {}));
class DownloadService {
    constructor() {
        this.getAppStoreUrl = (appStoreType, featureFlags, launcherService, isTflLink) => {
            const deepLinkUrl = launcherService.getProtocolLaunchDeeplinkUrl(LaunchType_1.LaunchType.GoToStore);
            switch (appStoreType) {
                case AppStoreType.Apple:
                    if ((isTflLink && featureFlags.disableAdjustForTfl) ||
                        (!isTflLink && featureFlags.disableAdjustForTfw)) {
                        return ConfigurationService_1.configurationService.getConfig().appStores.appleDirect.appLink(deepLinkUrl);
                    }
                    return ConfigurationService_1.configurationService
                        .getConfig()
                        .appStores.apple.appLink(featureFlags.enableNewUrlFormatForAdjust ? deepLinkUrl : window.location.href);
                case AppStoreType.Google:
                    return ConfigurationService_1.configurationService.getConfig().appStores.google.appLink(deepLinkUrl);
            }
        };
        this.onDownloadAppStore = (appStoreType, environment, joinInfo, launcherService, shouldCopyLinkToClipboard) => () => {
            if (shouldCopyLinkToClipboard) {
                const linkToCopy = launcherService.getProtocolLaunchDeeplinkUrl(LaunchType_1.LaunchType.GoToStore);
                Utils_1.Utils.copyTextToMobileClipboard(linkToCopy);
            }
            Logger_1.default.log(new TelemetryEvents_1.DownloadEvent(appStoreType === AppStoreType.Apple
                ? TelemetryEvents_1.DownloadEventOrigins.AppleAppStoreButton
                : TelemetryEvents_1.DownloadEventOrigins.GoogleAppStoreButton, {
                action: DownloadAction.RedirectToDowloadsPage,
                clientArchitecture: environment.architectureType,
                clientOsPlatform: environment.canonicalOs,
                success: true,
                automaticallyTriggered: false,
            }, joinInfo));
        };
        this.onDownloadExecutable = (joinInfo, environment, featureFlags, downloadState, launcherService) => (automaticallyTriggered) => {
            if (featureFlags.directDl) {
                const intermediateTimeoutInMS = 2000;
                const inProgressTimeoutInMS = 4000;
                const troubleTimeoutInMS = 8000;
                const installerDownloadUrl = this._getDownloadUrl(environment, featureFlags, launcherService);
                Logger_1.default.log(new TelemetryEvents_1.DownloadEvent(TelemetryEvents_1.DownloadEventOrigins.DownloadButton, {
                    action: DownloadAction.DirectDownload,
                    installerDownloadUrl: this._sanitizeInstallerDownloadUrl(installerDownloadUrl),
                    clientArchitecture: environment.architectureType,
                    clientOsPlatform: environment.canonicalOs,
                    success: true,
                    automaticallyTriggered: !!automaticallyTriggered,
                }, joinInfo));
                UrlHelper_1.UrlHelper.downloadFile(installerDownloadUrl);
                if (this.troubleTimeout) {
                    window.clearTimeout(this.troubleTimeout);
                    delete this.troubleTimeout;
                }
                if (this.intermediateTimeout) {
                    window.clearTimeout(this.intermediateTimeout);
                    delete this.intermediateTimeout;
                }
                if (this.inProgressTimeout) {
                    window.clearTimeout(this.inProgressTimeout);
                    delete this.inProgressTimeout;
                }
                if (launcherService.isAutoDownloadExperimentSupported() && automaticallyTriggered) {
                    this.intermediateTimeout = window.setTimeout(() => {
                        downloadState.update(DownloadState_1.DownloadStep.Intermediate);
                    }, intermediateTimeoutInMS);
                    this.inProgressTimeout = window.setTimeout(() => {
                        downloadState.update(DownloadState_1.DownloadStep.InProgress);
                    }, inProgressTimeoutInMS);
                    // Since the DownloadStep switches to Intermediate initially AFTER 2 seconds, the 10000 ms delay below ensures that the UFD is visible for exactly 8 seconds
                    // Note: The UFD is visible ONLY when DownloadStep is Intermediate or InProgress
                    this.troubleTimeout = window.setTimeout(() => {
                        downloadState.update(DownloadState_1.DownloadStep.PotentiallyCompleted);
                    }, intermediateTimeoutInMS + troubleTimeoutInMS);
                }
                else {
                    downloadState.update(DownloadState_1.DownloadStep.InProgress);
                    this.troubleTimeout = window.setTimeout(() => {
                        downloadState.update(DownloadState_1.DownloadStep.PotentiallyCompleted);
                    }, troubleTimeoutInMS);
                }
            }
            else {
                Logger_1.default.log(new TelemetryEvents_1.DownloadEvent(TelemetryEvents_1.DownloadEventOrigins.DownloadButton, {
                    action: DownloadAction.RedirectToDowloadsPage,
                    clientArchitecture: environment.architectureType,
                    clientOsPlatform: environment.canonicalOs,
                    success: true,
                    automaticallyTriggered: !!automaticallyTriggered,
                }, joinInfo));
                this.redirectToDownloadPage();
            }
        };
        this.redirectToDownloadPage = () => {
            UrlHelper_1.UrlHelper.openInTab(UrlHelper_1.UrlHelper.getOrigin() + ConfigurationService_1.configurationService.getConfig().urls.paths.download);
        };
    }
    getDownloadBehaviour(joinInfo, environment, featureFlags, launcherService, downloadState, shouldCopyLinkToClipboard) {
        switch (environment.os) {
            case Environment_1.OperatingSystems.Linux: {
                if (!downloadState) {
                    throw new Error(`downloadState should be defined for ${environment.os}`);
                }
                return {
                    i18n: "btn_download_linux_title",
                    onClick: this.onDownloadExecutable(joinInfo, environment, Object.assign(Object.assign({}, featureFlags), { directDl: false }), downloadState, launcherService),
                };
            }
            case Environment_1.OperatingSystems.Android: {
                const appStoreType = AppStoreType.Google;
                return {
                    onClick: this.onDownloadAppStore(appStoreType, environment, joinInfo, launcherService, !!shouldCopyLinkToClipboard),
                    i18n: "hdr_generic_download_btn",
                    appStore: {
                        imgSrc: (language) => ConfigurationService_1.configurationService
                            .getConfig()
                            .appStores.google.iconPath(language, ConfigurationService_1.configurationService.getConfig().publicPath),
                        type: appStoreType,
                        href: this.getAppStoreUrl(appStoreType, featureFlags, launcherService, joinInfo.isTflLink),
                    },
                };
            }
            case Environment_1.OperatingSystems.iOS: {
                const appStoreType = AppStoreType.Apple;
                return {
                    onClick: this.onDownloadAppStore(appStoreType, environment, joinInfo, launcherService, !!shouldCopyLinkToClipboard),
                    i18n: "hdr_generic_download_btn",
                    appStore: {
                        imgSrc: (language) => ConfigurationService_1.configurationService
                            .getConfig()
                            .appStores.apple.iconPath(language, ConfigurationService_1.configurationService.getConfig().publicPath),
                        type: appStoreType,
                        href: this.getAppStoreUrl(appStoreType, featureFlags, launcherService, joinInfo.isTflLink),
                    },
                };
            }
            case Environment_1.OperatingSystems.Win:
                if (!downloadState) {
                    throw new Error(`downloadState should be defined for ${environment.os}`);
                }
                return {
                    i18n: !environment.isWebClientSupportedBrowser
                        ? "hdr_mobile_acquire_app"
                        : "hdr_windows_download_btn",
                    onClick: this.onDownloadExecutable(joinInfo, environment, featureFlags, downloadState, launcherService),
                };
            case Environment_1.OperatingSystems.MacOS:
                if (!downloadState) {
                    throw new Error(`downloadState should be defined for ${environment.os}`);
                }
                return {
                    i18n: !environment.isWebClientSupportedBrowser
                        ? "hdr_mobile_acquire_app"
                        : "hdr_mac_download_btn",
                    onClick: this.onDownloadExecutable(joinInfo, environment, featureFlags, downloadState, launcherService),
                };
            default:
                if (!downloadState) {
                    return;
                }
                return {
                    i18n: "hdr_generic_download_btn",
                    onClick: this.onDownloadExecutable(joinInfo, environment, Object.assign(Object.assign({}, featureFlags), { directDl: false }), downloadState, launcherService),
                };
        }
    }
    _getDownloadUrl(environment, featureFlags, launcherService) {
        let installerPath = featureFlags.enableDesktopContextualInstaller
            ? ConfigurationService_1.configurationService.getConfig().apis.desktopContextualInstaller
            : ConfigurationService_1.configurationService.getConfig().apis.desktopInstaller;
        installerPath = UrlHelper_1.UrlHelper.addQueryParameter(installerPath, "env", environment.buildEnvironment, false);
        installerPath = UrlHelper_1.UrlHelper.addQueryParameter(installerPath, "plat", environment.canonicalOs, false);
        installerPath = UrlHelper_1.UrlHelper.addQueryParameter(installerPath, "arch", environment.architectureType, false);
        installerPath = UrlHelper_1.UrlHelper.addQueryParameter(installerPath, "download", "true", false);
        if (featureFlags.enableDesktopContextualInstaller) {
            installerPath = UrlHelper_1.UrlHelper.addQueryParameter(installerPath, "intent", environment.isTflEnvironment ? "life" : "work", false);
            const deepLink = launcherService.getContextualInstallDeeplinkUrl(LaunchType_1.LaunchType.GoToStore);
            installerPath = UrlHelper_1.UrlHelper.addQueryParameter(installerPath, "deeplink", deepLink, true);
        }
        return installerPath;
    }
    _sanitizeInstallerDownloadUrl(installerDownloadUrl) {
        const deepLink = decodeURIComponent(UrlHelper_1.UrlHelper.getQueryParameter("deeplink", installerDownloadUrl));
        let hashableDeepLink = UrlHelper_1.UrlHelper.removeQueryParameter(deepLink, "createdTime");
        hashableDeepLink = UrlHelper_1.UrlHelper.removeQueryParameter(hashableDeepLink, "deeplinkId");
        const hashedDeepLink = `<PII:${Utils_1.Utils.generateHash(hashableDeepLink)}>`;
        return UrlHelper_1.UrlHelper.replaceQueryParameter(installerDownloadUrl, "deeplink", hashedDeepLink);
    }
}
exports.DownloadService = DownloadService;
const downloadService = new DownloadService();
exports.default = downloadService;
