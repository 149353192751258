"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConfig_1 = require("../BaseConfig");
const Environment_1 = require("../../models/Environment");
const ag09Config = {
    env: Environment_1.BuildEnvironment.Ag09,
    ariaCollectorUri: "https://collector.azure.microsoft.scloud/Collector/3.0/",
    OneDsCollectorUri: "https://collector.azure.microsoft.scloud/OneCollector/1.0/",
    publicPath: "https://statics.teams.microsoft.scloud",
    telemetry: {
        tenantToken: "bcaedd8eaba449bdacadb4ec2081ddf1-bcc0edc5-60d3-44b8-b5d4-48d6e5eca5db-7979",
    },
    ecs: Object.assign(Object.assign({}, BaseConfig_1.default.ecs), { host: "https://config.ecs.teams.microsoft.scloud" }),
};
exports.default = ag09Config;
