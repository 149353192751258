"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Translate = void 0;
const React = require("react");
const CoreContext_1 = require("../../contexts/CoreContext");
const TranslationService_1 = require("../../services/TranslationService");
class Translate extends React.Component {
    render() {
        // This is MVP solution to render localized string.
        // We will add support of placeholder with children API when needed
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ language, languageBundle }) => TranslationService_1.default.translate(this.props.i18n, languageBundle, language)));
    }
}
exports.Translate = Translate;
