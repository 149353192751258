"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppStoreButtonRenderer = void 0;
const React = require("react");
const UrlHelper_1 = require("../../helpers/UrlHelper");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const Logger_1 = require("../../services/Logger");
const TranslationService_1 = require("../../services/TranslationService");
require("./appStoreButton.scss");
const Button_1 = require("./Button");
const Translate_1 = require("./Translate");
class AppStoreButtonRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.render = () => {
            var _a;
            const { downloadBehavior, requestedLanguage, languageBundle } = this.props;
            if (!downloadBehavior || !downloadBehavior.appStore) {
                return null;
            }
            const language = this.state.fallback ? "en-us" : requestedLanguage.toLocaleLowerCase();
            return this.props.newUI === true ? (React.createElement(Button_1.Button, { id: `${downloadBehavior.appStore.type}StoreButton`, className: `appStoreButton mobile ${(_a = this.props.buttonStyle) !== null && _a !== void 0 ? _a : "primary"}`, "data-tid": "download", onClick: () => {
                    if (this.props.onClickCallback) {
                        this.props.onClickCallback();
                    }
                    downloadBehavior.onClick();
                    if (downloadBehavior.appStore) {
                        UrlHelper_1.UrlHelper.redirect(downloadBehavior.appStore.href);
                    }
                } }, this.props.title ? this.props.title : React.createElement(Translate_1.Translate, { i18n: "btn_mobile_download" }))) : (React.createElement("a", { id: `${downloadBehavior.appStore.type}StoreButton`, className: "appStoreButton", href: downloadBehavior.appStore.href, onClick: () => {
                    if (this.props.onClickCallback) {
                        this.props.onClickCallback();
                    }
                    downloadBehavior.onClick();
                } },
                React.createElement("img", { id: `${downloadBehavior.appStore.type}StoreImg`, src: downloadBehavior.appStore.imgSrc(language), onError: this.onError(this.props.joinInfo, language), "aria-label": TranslationService_1.default.translate("hdr_generic_download_btn", languageBundle, requestedLanguage) })));
        };
        this.onError = (joinInfo, language) => () => {
            Logger_1.default.log(new TelemetryEvents_1.ImageLoadFailedEvent(joinInfo, language));
            // Avoid infinite loop of re-rendering
            if (!this.state.fallback) {
                this.setState({ fallback: true });
            }
        };
        this.state = {
            fallback: false,
        };
    }
}
exports.AppStoreButtonRenderer = AppStoreButtonRenderer;
